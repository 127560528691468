import styled from 'styled-components';


const Container = styled.div`
  padding-top: 15px;
  max-width: 100%;
  width: 100%;
  height: 100vh;
  max-height: 100%;
  box-sizing: border-box;
  position: relative;
  background: #181818;

  @media (max-width: 768px) {
    height: 100vh;
    overflow-x: hidden;
  }
`;

export const PDFContainer = styled.div`
  position: relative;
  box-sizing: border-box;
  padding-bottom: 30px;
  background: #181818;
  overflow: auto;

  > div {
    display: flex;
    justify-content: center;
    position: relative;
    box-sizing: border-box;

    > div {
      position: relative;

      canvas {
        margin: 0 auto;
        pointer-events: none !important;

        @media (max-width: 768px) {
          width: 95% !important;
          height: 95% !important;
        }
      }

      .react-pdf__Page__textContent {
        width: 100% !important;
        height: 100% !important;
      }
    }
  }

  .numeracao {
    margin: 0 auto;
    margin-bottom: 15px;
    border-radius: 5px;
    padding: 0 10px;
    position: sticky;
    top: 0px;
    width: 25%;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 14px;
    font-weight: 500;
    z-index: 999999999;

    @media (max-width: 768px) {
      width: 100% !important;
    }

    p {
      background: #313131;
      color: #fff;
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      opacity: 0.8;
    }
  }

  .action-buttons {
    display: flex;
    justify-content: center;
    align-items: center;

    span {
      margin-left: 10px;
      opacity: 1;
      color: #fff;
    }

    button {
      width: 40px;
      margin-left: 10px;
      background: #fff;
      height: 30px;
      border: 0;
      border-radius: 2px;
      line-height: 10px;
    }
  }
`;

export const Loading = styled.div`
  padding: 40px 0;
  width: 100%;
  display: flex;
  align-items: center;
  background: #f8f8f8;
  height: 90vh;
  max-width: 600px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  &> div {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;

    img {
      width: 350px;
    }

    h2 {
      max-width: 65%;
      text-align: center;
      font-size: 27px;
      color: #313131;
    }

    p {
      color: #313131;
      text-align: center;
    }
  }
`;

export default Container;
