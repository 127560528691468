import React, { useEffect, useState, useCallback } from 'react';
import { BiEdit, BiTrash } from 'react-icons/bi';
import { useHistory } from 'react-router-dom';

import Table from '../../components/Table';
import Pagination from '../../components/Pagination';
import Main from '../../components/Main';

import api from '../../services/api';

import { Container } from './styles';
import { Organization } from '../../interfaces/Organization';
import moment from 'moment';
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import { useToast } from '../../hooks/Toast';

const Organizations: React.FC = () => {
  const [items, setItems] = useState<Organization[]>([]);
  const [filteredAttr, setFilteredAttr] = useState('');
  const [loadingData, setLoadingData] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [refresh, setRefresh] = useState(false);

  const history = useHistory();
  const { addToast } = useToast();

  useEffect(() => {
    setLoadingData(true);
    api
      .get('organizations', { params: { search: filteredAttr, page: currentPage } })
      .then(response => {
        setItems(response.data.items);
        setLoadingData(false);
        setTotalPages(response.data.last_page);
      });
  }, [filteredAttr, currentPage, refresh]);

  const handleEdit = useCallback((item: Organization) => {
    history.push(`${process.env.PUBLIC_URL}/organizacoes/${item.id}`);
  }, []);

  let timeout: any;
  const handleSearchInput = useCallback((value) => {
    clearTimeout(timeout);
    timeout = setTimeout(() => {
      setFilteredAttr(value);
    }, 1000);
  }, [timeout]);

  const handleDelete = useCallback(async (item) => {
    try {
      confirmAlert({
        customUI: ({ onClose }) => {
          return (
            <div className="custom-ui">
              <h1>Confirmar ação?</h1>
              <p>Gostaria de deletar a org {item.name}? (Ação irreversível)</p>
              <button onClick={onClose}>Não</button>
              <button
                onClick={async () => {
                  await api.delete(
                    `organizations/${item.id}`
                  );

                  addToast({
                    type: 'success',
                    title: 'Organização deletada com sucesso!',
                  });
                  setRefresh(!refresh);

                  onClose();
                }}
              >
                Sim, deletar!
                </button>
            </div>
          );
        },
      });
    } catch (err) {
      addToast({
        type: 'error',
        title: 'Falha na exclusão da organização!',
        description: 'Tente novamente ou contate o suporte.',
        timer: 6000
      });
    }
  }, [history, refresh]);

  return (
    <Main>
      <Container>
        <div className="breadcrumb">
          <h2>Início &gt; Organizações</h2>
        </div>

        <Table>
          <div className="filters to-right">
            <input
              type="text"
              name="search"
              placeholder="Filtrar por nome"
              onChange={event => handleSearchInput(event.target.value)}
              className="filter-input"
            />
            <button
              type="button"
              className="new-btn"
              onClick={() => history.push(`${process.env.PUBLIC_URL}/organizacoes/novo`)}
            >
              Novo
            </button>
          </div>
          <div className="table-container">
            <table>
              <thead>
                <tr>
                  <td>Nome</td>
                  <td>Criada em</td>
                  <td>Editar</td>
                </tr>
              </thead>
              <tbody>
                {loadingData ? (
                  <tr>
                    <td colSpan={6}>
                      <p>Carregando organizações...</p>
                    </td>
                  </tr>
                ) : (
                  <>
                    {items.length > 0 ? (
                      <>
                        {items.map(item => (
                          <tr key={item.id!.toString()}>
                            <td>{item.name}</td>
                            <td>
                              {moment(item.created_at).format('DD/MM/YYYY')}
                            </td>
                            <td>
                              <button
                                type="button"
                                title="Excluir"
                                onClick={() => handleDelete(item)}
                              >
                                <BiTrash />
                              </button>
                              <button
                                type="button"
                                title="Editar"
                                onClick={() => handleEdit(item)}
                              >
                                <BiEdit />
                              </button>
                            </td>
                          </tr>
                        ))}
                      </>
                    ) : (
                      <tr>
                        <td colSpan={6}>
                          Nenhum resultado encontrado
                            </td>
                      </tr>
                    )}
                  </>
                )}
              </tbody>
            </table>
            <Pagination
              pages={totalPages}
              currentPage={currentPage}
              setPage={p => setCurrentPage(p)}
            />
          </div>

        </Table>
      </Container>
    </Main>
  );
};

export default Organizations;
