import React, { useEffect, useState, useCallback, useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import { BoxCalendar, Col, Container, Row } from './styles';
import { IoIosTime } from 'react-icons/io';
import DayPicker, { DayModifiers } from 'react-day-picker';
import { useToast } from '../../hooks/Toast';
import api from '../../services/api';
import moment from 'moment';

interface PageProps {
  submit(data: any): void;
}

const Step1: React.FC<PageProps> = ({ submit }) => {
  const [currentMonth, setCurrentMonth] = useState(new Date());
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [selectedHour, setSelectedHour] = useState<string | null>(null);

  const [availableHours, setAvailableHours] = useState<any[]>([]);
  const [configuredDays, setConfiguredDays] = useState<any[]>([]);
  const [allMonthConfigurations, setAllMonthConfigurations] = useState<any[]>([]);

  const { addToast } = useToast();

  useEffect(() => {
    api
      .get(`available-days`, {
        params: {
          year: moment(currentMonth).format('YYYY'),
          month: moment(currentMonth).format('MM'),
        }
      })
      .then(response => {
        const aux = response.data.success.items;

        const configuredDaysAux = aux?.map((conf: any) => {
          return moment(conf.date).format('D');
        });

        setConfiguredDays([...configuredDaysAux]);
        setAllMonthConfigurations([...aux]);
      })
      .catch(err => {
        console.log(err);
        // addToast({
        //   type: 'error',
        //   title: 'Oops!',
        //   description:
        //     err.response?.data?.error?.error_message || "Ocorreu um erro, tente novamente.",
        // });
      });
  }, [currentMonth]);

  useEffect(() => {
    api.get(`available-hours?date=${moment(selectedDate).format('YYYY-MM-DD')}`).then((res: any) => {
      const response_hours: any = [];
      res.data.available_hours_array?.map((hour: string) => {
        const now = moment().format('YYYY-MM-DD HH:mm');
        const _hour = moment(selectedDate).set({ hour: parseInt(hour.split(":")[0]), minute: parseInt(hour.split(":")[1]) });

        if (moment(_hour).isAfter(now)) {
          response_hours.push(hour);
        }
      });

      setAvailableHours([...response_hours]);
    }).catch((err: any) => {
      console.log(err);
      // addToast({
      //   type: 'error',
      //   title: `Oops!`,
      //   description: `Ocorreu um erro ao carregar a lista de horas disponíveis, verifique sua conexão e tente novamente.`,
      // });
    })
  }, [selectedDate]);

  const handleDateChange = useCallback((day: Date, modifiers: DayModifiers) => {
    if (modifiers.available && !modifiers.disabled) {
      setSelectedDate(day);
    }
  }, []);

  const handleMonthChange = useCallback((month: Date) => {
    setCurrentMonth(month);
  }, []);

  const daysInMonth = useCallback((month: number, year: number) => {
    return new Date(year, month, 0).getDate();
  }, []);

  const disabledDays = useMemo(() => {
    const countDays = moment().daysInMonth();
    const dates: Date[] = [];
    const year = currentMonth.getFullYear();
    const month = currentMonth.getMonth();

    for (let i = 1; i <= countDays; i++) {
      if (!configuredDays.includes(i.toString())) {
        dates.push(new Date(year, month, i));
      }
    }

    return dates;
  }, [currentMonth, configuredDays]);

  const handleSubmit = useCallback(() => {
    const formData = {
      date: moment(selectedDate).format('YYYY-MM-DD'),
      hour: selectedHour
    };

    submit(formData);
  }, [selectedDate, selectedHour]);

  return (
    <Container>
      <Row>
        <Col>
          <h2>PCI – Programa de Compliance Individual Shield Compliance.</h2>
          <p><IoIosTime size={27} /> Tempo médio 2 h 30 min</p><br />
          <p>
            Queira por gentileza clicar no dia e horários disponíveis para agendar o seu processo e, em sequência, clique em “confirmar”.
          </p>
          <br />

          <p>
            Caso sua necessidade esteja dentro de algum dia e horário indisponível, queira por gentileza entrar em contato conosco pelos canais abaixo, será um prazer poder ajustar sua necessidade à nossa demanda.
          </p>
          <br />

          <p>
            Telefone: (11) 98544-3344<br /><br />
            E-mail: <a href="mailto:contato@shieldcompliance.com.br">contato@shieldcompliance.com.br</a><br /><br />
            <a href="https://www.shieldcompliance.com.br" target="_blank">www.shieldcompliance.com.br</a><br /><br />
          </p>
        </Col>
        <Col>
          <h2>Selecione uma data</h2>
          <BoxCalendar>
            <DayPicker
              weekdaysShort={['D', 'S', 'T', 'Q', 'Q', 'S', 'S']}
              fromMonth={new Date()}
              disabledDays={[...disabledDays]}
              modifiers={{
                available: { daysOfWeek: [0, 1, 2, 3, 4, 5, 6] },
              }}
              selectedDays={selectedDate}
              onDayClick={handleDateChange}
              onMonthChange={handleMonthChange}
              months={[
                'Janeiro',
                'Fevereiro',
                'Março',
                'Abril',
                'Maio',
                'Junho',
                'Julho',
                'Agosto',
                'Setembro',
                'Outubro',
                'Novembro',
                'Dezembro',
              ]}
            />
          </BoxCalendar>
        </Col>
        <Col>
          <h2>Horários disponíveis</h2>
          {
            availableHours.length === 0 ? (
              <div className="hour-list">
                <p className="alert">Nenhum horário disponível, selecione outra data.</p>
                <div className="no-hour-item"></div>
                <div className="no-hour-item"></div>
                <div className="no-hour-item"></div>
                <div className="no-hour-item"></div>
                <div className="no-hour-item"></div>
              </div>
            ) : (
              <div className="hour-list">
                {availableHours.map((hour, index) => (
                  <div className={selectedHour === hour ? "hour-item active" : "hour-item"} key={index} onClick={() => setSelectedHour(hour)}><p>{hour}</p></div>
                ))}
              </div>
            )
          }
        </Col>
      </Row>
      <Row>
        {
          selectedDate && selectedHour && (
            <div className="buttons">
              <button type="button" className="confirm-button" onClick={handleSubmit}>Confirmar</button>
            </div>
          )
        }
      </Row>
    </Container>
  );
}

export default Step1;
