import styled, { keyframes } from 'styled-components';

const appearFromTop = keyframes`
  from {
    opacity: 0;
    transform: translateY(-50px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
`;

const appearFromBottom = keyframes`
  from {
    opacity: 0;
    transform: translateY(50px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
`;

const appearFromLeft = keyframes`
  from {
    opacity: 0;
    transform: translateX(-50px);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
`;

const appearFromRight = keyframes`
  from {
    opacity: 0;
    transform: translateX(50px);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
`;

export const Container = styled.div`
  display: flex;
  flex: 1;
  place-items: center;
  flex-direction: column;
  padding: 0 50px 30px;

  @media (max-width: 769px) {
    padding: 0 15px 15px;
  }

  .row {
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin-bottom: 30px;
    flex-wrap: wrap;
  }

  .alert {
    background: #f1f1f1;
    padding: 20px;
    font-weight: 500;
    text-align: center;
    color: #333;
    border-radius: 5px;
  }

  h3 {
    margin-bottom: 20px;
    color: #3f3f3f;
  }

  .chart-box {
    height: 300px;
  }
`;

export const TopBlocks = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 30px;
  flex-wrap: wrap;


  .block {
    background-color: #fff;
    width: calc(100% / 5 - 20px);
    height: 170px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 5px;
    padding: 0 20px;

    animation: ${appearFromTop} 1s;
    text-align: center;

    &:nth-child(2n + 1) {
      animation: ${appearFromBottom} 1s;
    }

    @media (max-width: 1024px) {
      width: calc(100% / 2 - 10px);
      margin-top: 20px;
      height: 120px;
    }

    h2 {
      color: #3f3f3f;
      font-weight: 600;
      font-size: 42px;
      line-height: 1;
      margin-bottom: 8px;
    }

    h4 {
      color: #3f3f3f;
      font-weight: 600;
      font-size: 18px;
      text-align: center;
    }

    p {
      color: #c2c2c2;
      margin-top: 20px;
      font-size: 14px;
    }

    div:nth-child(1) {
      flex: 1;
    }

    .image {
      height: 70px;
      width: 70px;
      margin-left: 20px;
      background: #e9edf5;
      border-radius: 40px;
      padding: 15px;

      img {
        width: 100%;
      }
    }

    .loading {
      flex: 1;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;

      h2 {
        background: #e2e2e2;
        width: 60px;
        height: 40px;
        margin-bottom: 15px;
        animation: shine 1s ease-in-out infinite;
        animation-fill-mode: forwards;
      }

      h4 {
        background: #e2e2e2;
        width: 230px;
        max-width: 90%;
        height: 24px;
        animation: shine 1s ease-in-out infinite;
        animation-fill-mode: forwards;
      }

      p {
        background: #e2e2e2;
        width: 180px;
        max-width: 80%;
        height: 18px;
        animation: shine 1s ease-in-out infinite;
        animation-fill-mode: forwards;
      }
    }
  }
`;

export const PieChart = styled.div`
  background: #fff;
  border-radius: 5px;
  overflow: hidden;
  width: calc(40% - 15px);
  display: flex;
  padding: 30px;
  flex-direction: column;
  animation: ${appearFromLeft} 1s;

  @media (max-width: 1024px) {
    width: 100%;
    margin-bottom: 30px;
  }

  .loading {
    text-align: center;
    position: relative;
    display: flex;
    justify-content: center;
    .chart {
      height: 300px;
      width: 300px;
      max-width: 90%;
      border-radius: 50%;
      background: #e2e2e2;
    }
  }
`;

export const BarChart = styled.div`
  background: #fff;
  border-radius: 5px;
  overflow: hidden;
  width: calc(60% - 15px);
  display: flex;
  padding: 30px;
  flex-direction: column;
  animation: ${appearFromRight} 1s;

  @media (max-width: 1024px) {
    width: 100%;
  }

  .loading {
    .chart {
      height: 300px;
      width: 100%;
      background: #e2e2e2;
    }
  }
`;

export const LineChart = styled.div`
  background: #fff;
  border-radius: 5px;
  overflow: hidden;
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 30px;
  animation: ${appearFromBottom} 1s;

  .loading {
    .chart {
      height: 300px;
      width: 100%;
      background: #e2e2e2;
    }
  }
`;

export const Filters = styled.div`
  display: flex;
  width: 100%;

  form {
    display: flex;
    justify-content: flex-end;
    width: 100%;

    @media (max-width: 768px) {
      flex-direction: column;
    }

    & > div {
      max-width: 27% !important;

      &:nth-child(1) {
        margin-right: 20px;
      }

      @media (max-width: 768px) {
        max-width: 100% !important;

        &:nth-child(1) {
          margin: 0;
        }
      }
    }
  }

`;
