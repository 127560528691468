import React, { useEffect, useState, useCallback } from 'react';
import { BiEdit } from 'react-icons/bi';
import { useHistory, useParams } from 'react-router-dom';

import Table from '../../components/Table';
import Pagination from '../../components/Pagination';
import Main from '../../components/Main';

import api from '../../services/api';

import { Container } from './styles';
import moment from 'moment';
import { Project } from '../../interfaces/Project';
import { FaDownload, FaExternalLinkAlt } from 'react-icons/fa';
import { AiFillEye } from 'react-icons/ai';
import { useToast } from '../../hooks/Toast';
import { saveAs } from 'file-saver';

const CustomerProjects: React.FC = () => {
  const [items, setItems] = useState<Project[]>([]);
  const [filteredAttr, setFilteredAttr] = useState('');
  const [loadingData, setLoadingData] = useState(true);
  const [downloading, setDownloading] = useState(false);
  const [downloadingProjectId, setDownloadingProjectId] = useState();
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);

  const history = useHistory();
  const { user_id } = useParams<any>();
  const { addToast, clearToasts } = useToast();

  useEffect(() => {
    setLoadingData(true);
    api
      .get('projects', { params: { search: filteredAttr, page: currentPage, user_id } })
      .then(response => {
        setItems(response.data.items);
        setLoadingData(false);
        setTotalPages(response.data.last_page);
      });
  }, [filteredAttr, currentPage, user_id]);

  let timeout: any;
  const handleSearchInput = useCallback((value) => {
    clearTimeout(timeout);
    timeout = setTimeout(() => {
      setFilteredAttr(value);
    }, 1000);
  }, [timeout]);

  const handleDownload = useCallback(async (project_id) => {
    addToast({
      type: 'info',
      title: 'Aguarde um momento',
      description:
        'Estamos preparando o arquivo, isso pode levar alguns segundos...',
      timer: 10000000000
    });

    setDownloadingProjectId(project_id);
    setDownloading(true);

    api.get(`projects/${project_id}/download`, { responseType: 'arraybuffer' }).then(res => {
      var blob = new Blob([res.data], { type: "application/pdf;charset=utf-8" });
      saveAs(blob, `Relatório - Shield Compliance`);
      clearToasts();
      setDownloading(false);
    }).catch((err) => {
      clearToasts();
      setDownloading(false);
      addToast({
        type: 'error',
        title: 'Oops!',
        description:
          'Solicitação inválida. Verifique os dados e tente novamente.',
        timer: 6000
      });
    });
  }, []);

  return (
    <Main>
      <Container>
        <div className="breadcrumb">
          <h2>Início &gt; Projetos</h2>
        </div>

        <Table>
          <div className="filters to-right">
            <input
              type="text"
              name="search"
              placeholder="Filtre por nome de projeto..."
              onChange={event => handleSearchInput(event.target.value)}
              className="filter-input"
            />
          </div>
          <div className="table-container">
            <table>
              <thead>
                <tr>
                  <td>Nome do projeto</td>
                  <td>Andamento do projeto</td>
                  <td>Data de disponibilização do relatório</td>
                  <td>Ações</td>
                </tr>
              </thead>
              <tbody>
                {loadingData ? (
                  <tr>
                    <td colSpan={6}>
                      <p>Carregando projetos...</p>
                    </td>
                  </tr>
                ) : (
                  <>
                    {items.length > 0 ? (
                      <>
                        {items.map(item => (
                          <tr key={item.id!.toString()}>
                            <td>{item.name}</td>
                            <td>{item.status}</td>
                            <td>{item.availability_date ? moment(item.availability_date).format("DD/MM/YYYY") : "-"}</td>
                            <td>

                              {item.status === "Em andamento" && item.project_link ? (
                                <button type="button" title="Acompanhar projeto">
                                  <a href={item.project_link} target="_blank"><FaExternalLinkAlt size={21} style={{ color: '#181818' }} /> Acompanhar</a>
                                </button>
                              ) : (
                                ""
                              )}

                              {
                                item.users[0].pivot.view_reports && item.users[0].pivot.view_reports && item.attached_document_availability ?
                                  <button type="button" title="Visualizar relatório">
                                    <a href={`/relatorio/${item?.id}`}
                                      style={{ color: '#181818' }}
                                      target="_blank"
                                    >
                                      <AiFillEye size={25} style={{ color: '#181818' }} /> Visualizar
                                    </a>
                                  </button>
                                  :
                                  ""
                              }

                              {
                                item.users[0].pivot.download_reports && item.attached_document_availability ?
                                  <button type="button" title="Download do relatório" onClick={() => handleDownload(item.id)} disabled={downloadingProjectId === item.id && downloading}>
                                    <a
                                      href={`#!`}
                                      style={{ color: '#181818' }}
                                    >
                                      <FaDownload size={25} />
                                      {downloadingProjectId === item.id && downloading ? "Baixando..." : "Download"}
                                    </a>
                                  </button>
                                  :
                                  ""
                              }

                            </td>
                          </tr>
                        ))}
                      </>
                    ) : (
                      <tr>
                        <td colSpan={6}>
                          Nenhum resultado encontrado
                            </td>
                      </tr>
                    )}
                  </>
                )}
              </tbody>
            </table>
            <Pagination
              pages={totalPages}
              currentPage={currentPage}
              setPage={p => setCurrentPage(p)}
            />
          </div>

        </Table>
      </Container>
    </Main>
  );
};

export default CustomerProjects;
