import React, { useRef, useState, useEffect, StrictMode } from 'react';
import ReactDatePicker, { ReactDatePickerProps } from 'react-datepicker';
import { useField } from '@unform/core';
import { registerLocale, setDefaultLocale } from 'react-datepicker';
import ptBR from 'date-fns/locale/pt-BR';
import 'react-datepicker/dist/react-datepicker.css';
import ReactInputMask from 'react-input-mask';
import moment from 'moment';

import { Container, Error, Label } from './styles';
import { FiAlertCircle } from 'react-icons/fi';

interface Props extends Omit<ReactDatePickerProps, 'onChange'> {
  name: string;
  fullWidth?: boolean;
  label?: string;
}

const DatePicker: React.FC<Props> = ({
  name,
  fullWidth = false,
  label,
  ...rest
}) => {
  const datepickerRef = useRef(null);
  const { fieldName, registerField, defaultValue, error, clearError } = useField(name);
  const [date, setDate] = useState(defaultValue || null);
  useEffect(() => {
    registerField({
      name: fieldName,
      ref: datepickerRef.current,
      // path: 'props.selected',
      clearValue: (ref: any) => {
        ref.clear();
      },
      setValue: (e, v) => {
        // const dateAux = moment(v, "YYYY-MM-DD").add(1, 'days').format('YYYY-MM-DD');
        setDate(new Date(v).setUTCHours(23));
      },
      getValue: () => {
        const x: any = datepickerRef?.current;
        return x.props?.selected;
      }
    });
  }, [fieldName, registerField]);
  return (
    <Label fullWidth={fullWidth} className="label">
      {!!label && <strong>{label}</strong>}
      <Container isErrored={!!error}>
        <ReactDatePicker
          ref={datepickerRef}
          selected={date}
          onChange={setDate}
          locale={ptBR}
          {...rest}
          onFocus={() => clearError()}
          customInput={
            <ReactInputMask
              mask="99/99/9999"
              placeholder="mm/dd/yyyy"
              formatChars={{ '9': '[0-9]', '?': '[0-9 ]' }}
              maskChar={null}
            />
          }
        />
        {error && (
          <Error title={error}>
            <FiAlertCircle color="#c53030" size={20} />
          </Error>
        )}
      </Container>
    </Label>
  );
};
export default DatePicker;
