import React, { useState, useCallback, useRef, useEffect, ChangeEvent } from 'react';
import { Form } from '@unform/web';
import { FormHandles } from '@unform/core';
import * as Yup from 'yup';
import getValidationErrors from '../../utils/getValidationErrors';
import 'react-confirm-alert/src/react-confirm-alert.css';
import { useParams, useHistory } from 'react-router-dom';

import { useToast } from '../../hooks/Toast';

import Input from '../../components/Input';
import Button from '../../components/Button';

import { Col, FormContainer, Row } from './styles';
import api from '../../services/api';
import { FiXCircle, FiDelete, FiX, FiPlus, FiTrash } from 'react-icons/fi';
import { RiDeleteBack2Fill } from "react-icons/ri";

import Main from '../../components/Main';
import CustomSelect from '../../components/CustomSelect';
import { User } from '../../interfaces/User';
import { v4 as uuid } from 'uuid';
import Resizer from 'react-image-file-resizer';
import Select from '../../components/Select';
import InputFile from '../../components/InputFile';
import Textarea from '../../components/Textarea';
import { Project } from '../../interfaces/Project';
import moment from 'moment';
import { confirmAlert } from 'react-confirm-alert';
import { AiFillEye } from 'react-icons/ai';
import { HiTrash } from 'react-icons/hi';

interface CustomSelectI {
  value: string;
  label: string;
}

interface DefaultOptions {
  value: number;
  label: string;
}

const FormModal: React.FC = () => {
  const formRef = useRef<FormHandles>(null);

  const [item, setItem] = useState<Project | null>(null);
  const [organizations, setOrganizations] = useState<CustomSelectI[]>([]);

  const [allCustomers, setAllCustomers] = useState<DefaultOptions[]>([]);
  const [customersCount, setCustomersCount] = useState<number[]>([]);
  const [customers, setCustomers] = useState<any[]>([]);
  const [selectedCustomer, setSelectedCustomer] = useState<any[]>([]);
  const [selectedOrganizations, setSelectedOrganizations] = useState<any[]>([]);
  const [sending, setSending] = useState<boolean>(false);
  const [sendingEmail, setSendingEmail] = useState<boolean>(false);
  const [hasFileSelected, setHasFileSelected] = useState<boolean>(false);
  const [refresh, setRefresh] = useState<boolean>(false);
  const [emailContentChange, setEmailContentChange] = useState<boolean>(false);

  const history = useHistory();
  const { id } = useParams<any>();

  const { addToast } = useToast();

  useEffect(() => {
    api.get('all-customers', {
      params: {
        organizations: JSON.stringify(selectedOrganizations)
      }
    }).then(res => {
      const aux = res.data.items.map((item: User) => {
        return { value: item.id, label: item.name }
      });

      setAllCustomers([...aux]);
      setCustomers([...aux]);
    }).catch(err => {
      addToast({
        type: 'error',
        title: 'Falha na requisição',
        description:
          'Ocorreu um erro ao carregar a lista de clientes, verifique sua conexão e tente novamente.',
      });
    });
  }, [selectedOrganizations]);

  useEffect(() => {
    api.get('all-organizations').then(res => {
      const aux = res.data.items.map((item: User) => {
        return { value: item.id, label: item.name }
      });

      setOrganizations([...aux]);
    }).catch(err => {
      addToast({
        type: 'error',
        title: 'Falha na requisição',
        description:
          'Ocorreu um erro ao carregar a lista de organizações, verifique sua conexão e tente novamente.',
      });
    });
  }, []);

  useEffect(() => {
    if (id && id !== 'novo') {
      api
        .get(`projects/${id}`)
        .then(response => {
          setItem(response.data.item);

          const users = response.data.item.users.map((user: any) => {
            customersCount.push(1);
            return {
              user_id: { value: user.id, label: user.name },
              view_reports: { value: user.pivot.view_reports, label: user.pivot.view_reports ? "Sim" : "Não" },
              download_reports: { value: user.pivot.download_reports, label: user.pivot.download_reports ? "Sim" : "Não" },
            }
          });

          const organizations = response.data.item.organizations?.map((org: any) => {
            return {
              value: org.id,
              label: org.name
            }
          });

          setCustomersCount([...customersCount]);

          formRef?.current?.setData({
            ...response.data.item,
            users,
            organizations,
            status: { value: response.data.item.status, label: response.data.item.status },
            attached_document_availability: { value: response.data.item.attached_document_availability, label: response.data.item.attached_document_availability ? "Disponível" : "Indisponível" },
            type: { value: response.data.item.type, label: response.data.item.type },
            availability_date: moment(response.data.item.availability_date).format('YYYY-MM-DD'),
          });

          setSelectedCustomer([...users]);
        })
        .catch(err => {
          console.log(err);
          addToast({
            type: 'error',
            title: 'Falha na requisição',
            description:
              'Ocorreu um erro ao carregar os dados, verifique sua conexão e tente novamente.',
          });
        });
    }
  }, [id, refresh]);

  const handleSubmit = useCallback(
    async (data: Project) => {
      setSending(true);
      let formData;

      if (data.attached_document && !!data.attached_document) {
        const filename = uuid();
        formData = new FormData();
        formData.append('file', data.attached_document, filename);
        data.attached_content_type = data.attached_document.type;
        data.attached_document = filename;
      }

      try {
        formRef.current?.setErrors({});

        const schema = Yup.object().shape({
          name: Yup.string().required('Nome é obrigatório'),
          status: Yup.string().required('Status é obrigatório'),
          project_link: Yup.string().nullable(),
          type: Yup.string().required('Tipo é obrigatório'),
          availability_date: !data.attached_document_availability ? Yup.string().nullable() : Yup.string().required('Data de disponibilização é obrigatória'),
          attached_document: Yup.string().nullable(),
          organization: Yup.array().nullable(),
          users: customersCount.length > 0 ? Yup.array().of(
            Yup.object().shape({
              user_id: Yup.string().required('Informe o usuário'),
              view_reports: Yup.string().required('Informe a permissão'),
              download_reports: Yup.string().required('Informe a permissão'),
            })
          ) : Yup.array().nullable(),
          details: Yup.string().nullable(),
          notification_email_content: Yup.string().nullable(),
          attached_document_availability: Yup.bool().required('Informe a disponibilidade do relatório'),
        });

        await schema.validate(data, {
          abortEarly: false,
        });

        let response;
        if (item) {
          response = await api.put(`projects/${item.id}`, data);

          if (data.attached_document && !!data.attached_document) {
            await api.post(`/projects/file`, formData).catch((err) => console.log(err));
          }

          setCustomersCount([]);
          setRefresh(!refresh);
        } else {
          response = await api.post('projects', data);

          if (data.attached_document && !!data.attached_document) {
            await api.post(`/projects/file`, formData).catch((err) => console.log(err));
          }

          setCustomersCount([]);
          history.push(`${process.env.PUBLIC_URL}/projetos/${response.data.success.project}`);
        }

        addToast({
          type: 'success',
          title: `Cadastro ${item ? 'atualizado' : 'realizado'}`,
          description: response.data.success.message,
        });
        setHasFileSelected(false);
        setEmailContentChange(false);
        setSending(false);
        // history.push(`/projetos`);
      } catch (err) {
        setSending(false);
        if (err instanceof Yup.ValidationError) {
          console.log(err);
          const errors = getValidationErrors(err);

          addToast({
            type: 'error',
            title: 'Atenção!',
            description:
              'Preencha todos os campos obrigatórios',
          });
          formRef.current?.setErrors(errors);

          return;
        } else {
          addToast({
            type: 'error',
            title: 'Falha na requisição',
            description:
              'Ocorreu um erro ao salvar, verifique os dados e tente novamente',
          });
        }
      }
    },
    [history, item, customersCount, refresh],
  );

  const addUser = useCallback(() => {
    setCustomersCount([...customersCount, 1]);
  }, [customersCount]);

  const removeUser = useCallback(async (i) => {
    const field_value: any = formRef?.current?.getData();

    customersCount.pop();
    setCustomersCount([...customersCount]);

    const aux: any = field_value?.users.filter((item: any, index: any) => index !== i);

    aux.map((item: any, index: number) => {
      formRef?.current?.setFieldValue(`users[${index}].user_id`, allCustomers.filter(x => x.value === item.user_id)[0]);
      formRef?.current?.setFieldValue(`users[${index}].view_reports`, { value: item.view_reports, label: item.view_reports ? "Sim" : "Não" });
      formRef?.current?.setFieldValue(`users[${index}].download_reports`, { value: item.download_reports, label: item.download_reports ? "Sim" : "Não" });
    });
  }, [customersCount, formRef.current]);

  useEffect(() => {
    if (formRef.current) {
      const fieldData: any = formRef?.current?.getData();
      let options = [];
      if (fieldData) {
        options = allCustomers?.filter(item => !fieldData?.users?.find((x: any) => item.value === x.user_id));
      } else {
        options = allCustomers;
      }
      setCustomers([...options]);
    }
  }, [selectedCustomer]);

  const handleSelectedOrganizations = useCallback((selecteds) => {
    setSelectedOrganizations([...selecteds.map((item: any) => item.value)]);
  }, []);

  const handleDeleteFile = useCallback(async () => {
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <div className="custom-ui">
            <h1>Confirmar ação?</h1>
            <p>Gostaria de deletar este relatório? (Ação irreversível)</p>
            <button onClick={onClose}>Não</button>
            <button
              onClick={async () => {
                await api.delete(`/projects/${item?.id}/file`)
                  .then((res) => {
                    addToast({
                      type: 'success',
                      title: 'Pronto!',
                      description:
                        'Deletado com sucesso.',
                    });
                    setCustomersCount([]);
                    setRefresh(!refresh);
                  })
                  .catch((err) => {
                    addToast({
                      type: 'error',
                      title: 'Oops!',
                      description:
                        'Ocorreu um erro ao deletar, tente novamente.',
                    });
                  });
                onClose();
              }}
            >
              Sim, confirmar!
            </button>
          </div>
        );
      },
    });
  }, [item, refresh]);

  const sendEmails = useCallback(() => {
    setSendingEmail(true);
    const emailContent = formRef?.current?.getFieldValue('notification_email_content');
    if (!emailContent || emailContent === "") {
      addToast({
        type: 'error',
        title: 'Oops!',
        description:
          'Informe o conteúdo do e-mail.',
        timer: 6000
      });
      setSendingEmail(false);
      return;
    }

    api.get(`projects/${item?.id}/custom-emails`).then(res => {
      console.log(res)
      setSendingEmail(false);
      addToast({
        type: 'success',
        title: 'Pronto!',
        description:
          'E-mail enviado com sucesso.',
      });
    }).catch(err => {
      addToast({
        type: 'error',
        title: 'Oops!',
        description:
          'Ocorreu um erro ao enviar e-mail, tente novamente.',
        timer: 6000
      });
      setSendingEmail(false);
      console.log(err)
    });
  }, [item]);

  return (
    <Main>
      <FormContainer>
        <div className="breadcrumb">
          <h2>Início / {item ? 'Editar' : 'Novo'} projeto</h2>
        </div>
        <Form
          onSubmit={handleSubmit}
          ref={formRef}
        >
          <button type="button" onClick={() => history.push(`/projetos`)} className="close-btn">
            <FiXCircle size={30} />
          </button>
          <Input name="name" label="Nome" />
          <Select
            name="status"
            options={[
              { value: "Em andamento", label: "Em andamento" },
              { value: "Encerrado", label: "Encerrado" },
            ]}
            isMulti={false}
            isSearchable={false}
            isClearable={false}
            label="Status"
            placeholder="Selecionar..."
          />
          <Input name="project_link" label="Link para acompanhamento do projeto" />
          <Select
            name="type"
            options={[
              { value: "Treinamento", label: "Treinamento" },
              { value: "Compliance", label: "Compliance" },
              { value: "Investigação", label: "Investigação" },
            ]}
            isMulti={false}
            isSearchable={false}
            isClearable={false}
            label="Tipo (Treinamento, Compliance ou Investigação)"
            placeholder="Selecionar..."
          />
          <Input type="date" name="availability_date" label="Data de disponibilização" />
          <Select
            name="attached_document_availability"
            options={[
              { value: true, label: "Disponível" },
              { value: false, label: "Indisponível" },
            ]}
            isMulti={false}
            isSearchable={false}
            isClearable={false}
            label="Status do relatório"
            placeholder="Disponível/Indisponível"
          />
          <InputFile name="attached_document" label="Anexo" onChange={() => setHasFileSelected(!hasFileSelected)} fullWidth />

          <div className="delete-file">
            {
              item && item?.attached_document && !!item?.attached_document && !hasFileSelected && (
                <>
                  <a href={`${item.attached_document}`} target="_blank" className="view-file">
                    <p>
                      Visualizar
                      <AiFillEye size={22} />
                    </p>
                  </a>
                  <div onClick={handleDeleteFile}>
                    <p>Deletar arquivo</p>
                    <HiTrash size={22} />
                  </div>
                </>
              )
            }

            {
              hasFileSelected && (
                <div onClick={() => {
                  setHasFileSelected(!hasFileSelected);
                  formRef.current?.clearField(`attached_document`);
                }}>
                  <p>Remover selecionado</p>
                  <RiDeleteBack2Fill size={22} />
                </div>
              )
            }
          </div>

          <CustomSelect
            name="organizations"
            options={organizations}
            label="Vincular organizações"
            placeholder="Selecionar..."
            noOptionsMessage={() => "Sem resultados..."}
            fullWidth
            handleSelected={handleSelectedOrganizations}
          />

          <h4>Clientes vinculados</h4>
          {
            customersCount.length > 0 ?
              customersCount.map((item: any, index: number) => (
                <div className="scope" key={index.toString()}>
                  <button className="rm" type="button" onClick={() => removeUser(index)}><FiX /></button>
                  <Row>
                    <Col>
                      <div>
                        <Select
                          name={`users[${index}].user_id`}
                          label="Cliente"
                          options={customers}
                          placeholder="Escolher..."
                          noOptionsMessage={() => "Sem mais resultados para as organizações selecionadas..."}
                          fullWidth
                          onChange={(obj: any) => {
                            if (obj) {
                              const aux = customers.filter(item => item.value !== obj.value);
                              setSelectedCustomer(aux);
                            }
                          }}
                        />
                      </div>
                    </Col>
                    <Col>
                      <div>
                        <Select
                          name={`users[${index}].view_reports`}
                          label="Visualizar relatórios"
                          options={[
                            { value: true, label: "Sim" },
                            { value: false, label: "Não" },
                          ]}
                          placeholder="Escolher..."
                          noOptionsMessage={() => "Sem resultados..."}
                          fullWidth
                        />
                      </div>
                    </Col>
                    <Col>
                      <div>
                        <Select
                          name={`users[${index}].download_reports`}
                          label="Download relatórios"
                          options={[
                            { value: true, label: "Sim" },
                            { value: false, label: "Não" },
                          ]}
                          placeholder="Escolher..."
                          noOptionsMessage={() => "Sem resultados..."}
                          fullWidth
                        />
                      </div>
                    </Col>
                  </Row>
                </div>
              )) :
              (
                <div className="no-results">
                  <p>Vincule clientes à este projeto</p>
                </div>
              )
          }

          <button type="button" className="new-btn" onClick={() => addUser()} disabled={allCustomers.length > 0 && allCustomers.length === customersCount.length}>
            {allCustomers.length > 0 && allCustomers.length === customersCount.length ? "Limite atingido" : <>Adicionar usuário <FiPlus /></>}
          </button>

          <Textarea name="details" label="Descritivo do projeto" placeholder="Faça alguma observação..." fullWidth />

          <Textarea name="notification_email_content" label="Descreva o texto do e-mail de notificação de relatório" placeholder="..." fullWidth
            onInput={(e) => {
              console.log(e.currentTarget.value)
              setEmailContentChange(true);
            }}
          />

          <div className="buttons">
            <Button type="submit" disabled={sending}>{sending ? "Salvando..." : "Salvar"}</Button>

            {item && (
              <Button type="button" className="prev" disabled={sendingEmail || emailContentChange}
                onClick={() => sendEmails()}
              >{sendingEmail ? "Enviando..." : emailContentChange ? "Salve as alterações" : "Enviar e-mail"}</Button>
            )}
          </div>
        </Form>
      </FormContainer>
    </Main >
  );
};

export default FormModal;
