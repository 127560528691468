import styled from 'styled-components';

export const Container = styled.button`
  display: block;
  float: right;
  width: 237px;
  height: 40px;
  background-color: #181818;
  border: 1px solid #181818;
  box-shadow: 0px 3px 6px rgb(0 0 0 / 16%);
  border-radius: 5px;
  color: #fff;
  font-size: 20px;
  transition: all 0.3s;

  &:hover {
    background-color: transparent;
    color: #181818;
  }
`;
