import styled, { keyframes } from 'styled-components';

const appearFromTop = keyframes`
  from {
    opacity: 0;
    transform: translateY(-50px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
`;

export const Container = styled.div`
  background-color: #fff;
  padding: 10px 30px;
  position: absolute;
  width: calc(100% - 170px);
  top: 0;
  animation: ${appearFromTop} 1s;

  @media (max-width: 768px) {
    width: 100%;
    position: relative;
    padding: 15px;
  }

  .left{
    display: flex;
    align-items: center;
  }

  h3 {
    color: #333;
    font-weight: 500;
  }

  .userAvatar .avatar {
    height: 50px;
    width: 50px;
    border-radius: 30px;
    margin-right: 10px;
    background: linear-gradient(#181818,#6a6a6a);
    color: #fff;
    font-size: 24px;
    font-weight: 500;

    display: flex;
    justify-content: center;
    align-items: center;
  }
`;
