import React, { useEffect, useState, useCallback, useRef } from 'react';
import { Col, Container, Row } from './styles';
import { IoIosTime } from 'react-icons/io';
import { Form } from '@unform/web';
import Input from '../../components/Input';
import InputMask from '../../components/InputMask';
import InputFile from '../../components/InputFile';
import Textarea from '../../components/Textarea';

import * as Yup from 'yup';
import getValidationErrors from '../../utils/getValidationErrors';
import { FormHandles } from '@unform/core';

import Dropzone from 'react-dropzone-uploader';
import 'react-dropzone-uploader/dist/styles.css'
import { uuid } from 'uuidv4';
import api from '../../services/api';
import { useToast } from '../../hooks/Toast';

interface PageProps {
  goBack(): void;
  formData?: any;
}

const Step2: React.FC<PageProps> = ({ goBack, formData }) => {
  const [hasFileSelected, setHasFileSelected] = useState<boolean>(false);
  const [selectedFiles, setSelectedFiles] = useState<any[]>([]);
  const [sending, setSending] = useState<boolean>(false);

  const [phoneMask, setPhoneMask] = useState('(99) 9999-9999?');
  const formRef = useRef<FormHandles>(null);
  const { addToast } = useToast();

  const handleSubmit = useCallback(async (data) => {
    try {
      formRef.current?.setErrors({});

      let schema = Yup.object().shape({
        customer_name: Yup.string().required('Nome obrigatório'),
        customer_email: Yup.string().required('E-mail obrigatório'),
        customer_phone: Yup.string().required('Telefone obrigatório'),
        files: Yup.object().nullable(),
        additional_informations: Yup.string().nullable(),
      });

      await schema.validate(data, {
        abortEarly: false,
      });

      setSending(true);

      var fd: any;

      if (hasFileSelected) {
        fd = new FormData();

        var fileNames = [];

        try {
          selectedFiles.map((attached: any, index: number) => {
            const filename = uuid();
            fd.append('file', attached.file, filename);
            fileNames.push(filename);
          });
        } catch (error) {
          addToast({
            type: 'error',
            title: `Oops!`,
            description: `Ocorreu um erro ao fazer o anexo, atualize a página e tente novamente.`,
            timer: 6000
          });

          return;
        }
      }

      api.post(`schedules`, {
        ...data,
        files: fileNames,
        date: formData.date,
        hour: formData.hour,
      })
        .then(async () => {
          if (hasFileSelected) {
            await api.post(`schedules/file`, fd).catch((err) => console.log(err));
          }

          addToast({
            type: 'success',
            title: `Pronto!`,
            description: `Agendamento realizado com sucesso.`,
            timer: 6000
          });
          setSending(false);
          goBack();
        })
        .catch(err => {
          setSending(false);
          console.log(err);
          addToast({
            type: 'error',
            title: `Oops!`,
            description: err?.response?.data?.error?.error_message || `Ocorreu um erro ao salvar agendamento, verifique sua conexão e tente novamente.`,
          });
        });
    } catch (err) {
      console.log(err);
      if (err instanceof Yup.ValidationError) {
        const errors = getValidationErrors(err);

        formRef.current?.setErrors(errors);
      }
    }
  }, [hasFileSelected, selectedFiles]);

  // called every time a file's `status` changes
  const handleChangeStatus = useCallback(({ meta, file }: any, status: any) => {
    if (status === "done") {
      selectedFiles.push({ meta, file });
      setSelectedFiles([...selectedFiles]);
    }

    if (status === "removed") {
      var files = selectedFiles.filter(fileAux => fileAux.meta.id !== meta.id);
      setSelectedFiles([...files]);
    }
  }, [selectedFiles]);

  useEffect(() => {
    if (selectedFiles.length > 0) {
      setHasFileSelected(true);
    } else {
      setHasFileSelected(false);
    }
  }, [selectedFiles]);

  return (
    <Container>
      <Row>
        <Col className="step2">
          <h2>Por gentileza, insira nos campos ao lado as informações do candidato.</h2>
          <p><IoIosTime size={27} /> Tempo médio 2 h 30 min</p><br />
          <p>
            Utilize o campo anexo para inserir currículos e/ou outros documentos importantes.
            </p><br />
          <p>
            Caso queira, digite as informações que desejar sobre o candidato, vaga ou outra informação que considerar importante, A Shield Compliance terá acesso a todo o conteúdo digitado e estará de prontidão para compreender melhor sua demanda e trabalhar em soluções cada vez mais personalizadas para a sua necessidade.
            </p><br />
          <p>
            Atenciosamente,
            </p><br />
          <p>
            André Costa de Almeida | CFI | CPC-A | PDPE | MBA
            </p><br />

          <p>
            E-mail: <a href="mailto:contato@shieldcompliance.com.br">contato@shieldcompliance.com.br</a><br /><br />
            <a href="https://www.shieldcompliance.com.br" target="_blank">www.shieldcompliance.com.br</a><br /><br />
          </p>
        </Col>

        <Col className="step2">
          <h2>Preencha os campos abaixo para confirmar</h2>
          <Form onSubmit={handleSubmit} ref={formRef}>
            <Input name="customer_name" label="Nome completo do candidato" fullWidth />
            <Input name="customer_email" label="E-mail do candidato" fullWidth />
            <InputMask
              name="customer_phone"
              label="Telefone do candidato"
              mask={phoneMask}
              formatChars={{ '9': '[0-9]', '?': '[0-9 ]' }}
              maskChar={null}
              onChange={(event: any) => {
                if (event.target.value.length >= 15) {
                  setPhoneMask('(99) 99999-9999');
                } else {
                  setPhoneMask('(99) 9999-9999?');
                }
              }}
              fullWidth
            />
            {/* <InputFile name="files" label="Anexo(s)" fullWidth onChange={() => setHasFileSelected(!hasFileSelected)} />
            {
              hasFileSelected && (
                <div className="remove-attachment" onClick={() => {
                  setHasFileSelected(!hasFileSelected);
                  formRef.current?.clearField(`files`);
                }}>
                  <p>Remover selecionado</p>
                  <RiDeleteBack2Fill size={22} />
                </div>
              )
            } */}
            <Textarea name="additional_informations" label="Informações adicionais" placeholder="Acrescente aqui alguma informação adicional que possa ser útil durante a entrevista" fullWidth />
            <Dropzone
              multiple
              onChangeStatus={handleChangeStatus}
              accept="image/*,application/*,text/*"
              inputContent="Arraste para anexar arquivo(s) ou clique para selecionar"
              inputWithFilesContent="Anexar mais arquivo(s)"
            />

            <div className="buttons">
              <button type="button" className="back-button" onClick={goBack}>Etapa anterior</button>
              <button type="submit" className="confirm-button" disabled={sending}>{sending ? "Enviando..." : "Agendar"}</button>
            </div>
          </Form>
        </Col>
      </Row>
    </Container>
  );
}

export default Step2;
