import { shade } from 'polished';
import styled from 'styled-components';

export const Container = styled.div`
  padding: 0 50px;
  place-items: center;
  flex-direction: column;

  @media (max-width: 769px) {
    padding: 0 15px;
  }
`;

export const FormContainer = styled.div`
  padding: 0 50px;
  place-items: center;
  flex-direction: column;

  @media (max-width: 769px) {
    padding: 0 15px;
  }

  form {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    position: relative;
    background: #fff;
    padding: 30px;
    border-radius: 5px;
    margin-bottom: 30px;

    @media (max-width: 769px) {
      padding: 20px;
    }

    button[type='submit'] {
      border-radius: 5px;
    }

    .break-line {
      width: 100%;
    }

    & .buttons {
      display: flex;
      width: 100%;
      justify-content: flex-end;

      button {
        line-height: 10px;

        &.prev {
          border: 1px solid #181818;
          color: #181818;
          background-color: transparent;
          margin-right: 15px;
        }

        &:disabled {
          background: #f1f1f1;
          border: 1px solid #ddd;
          cursor: not-allowed;
          color: #ccc;
        }
      }
    }

    .close-btn {
      position: absolute;
      right: 0;
      top: -50px;
      padding: 0;
      line-height: 20px;
      background: transparent;
      border: 0;
      color: #181818;
    }
  }

  .all-configurations {
    width: 60%;

    & .alert {
      background: #f5f5f5;
      border-radius: 5px;
      text-align: left;
      padding: 15px;
      font-weight: 600;
    }

    @media(max-width: 768px) {
      width: 100%;
      margin-bottom: 20px;

      & .alert {
        text-align: center;
      }
    }

    h2 {
      margin-bottom: 20px;
    }

    > div {
      max-height: 369px;
      overflow-y: auto;

      @media(max-width: 768px) {
        max-height: 250px;
      }

      & > div {
        background: #fff;
        padding: 3px 20px;
        border-radius: 5px;

        &:nth-child(2n + 1) {
          background-color: #f5f5f5;
        }
      }

      ::-webkit-scrollbar {
        width: 13px;
        height: 13px;
      }
      ::-webkit-scrollbar-thumb {
        background: #181818;
        border-radius: 10px;
      }
      ::-webkit-scrollbar-thumb:hover{
        background: #181818;
      }
      ::-webkit-scrollbar-track{
        background: #ffffff;
        border-radius: 10px;
        box-shadow: inset 7px 10px 12px #f0f0f0;
      }

      p {
        text-align: left;
        margin: 5px 0;
      }
    }
  }
`;

export const BoxCalendar = styled.div`
  width: 380px;
  max-width: 100%;
  text-align: center;
  /* margin: 0 auto; */
  margin-bottom: 20px;

  h2 {
    margin-bottom: 20px;
  }

  @media (max-width: 1230px) {
    margin-left: 0;
    margin-bottom: 30px;
  }

  .DayPicker {
    background: #181818;
    border-radius: 10px;
  }

  .DayPicker-wrapper {
    padding-bottom: 0;
  }

  .DayPicker,
  .DayPicker-Month {
    width: 100%;
  }

  .DayPicker-Month {
    border-collapse: separate;
    border-spacing: 8px;
    margin: 16px;
    width: calc(100% - 32px);
  }

  .DayPicker-Day {
    width: 40px;
    height: 40px;
    color: #fff;
    background: #313131;
    cursor: pointer;
  }

  .DayPicker-Day--outside {
    color: #8B9898;
    cursor: default;
    color: transparent !important;
    background: transparent !important;
  }

  .DayPicker-Day--available:not(.DayPicker-Day--outside) {
    background: #2b2b2b;
    border-radius: 50px;
    color: #fff;
  }

  .DayPicker:not(.DayPicker--interactionDisabled)
    .DayPicker-Day:not(.DayPicker-Day--disabled):not(.DayPicker-Day--selected):not(.DayPicker-Day--outside):hover {
    background: ${shade(0.2, '#2b2b2b')};
  }

  .DayPicker-Day--today {
    font-weight: normal;
  }

  .DayPicker-Day--disabled {
    color: #b4b4b4 !important;
    background: transparent !important;
  }

  .DayPicker-Day--selected {
    background: #fff !important;
    /* border-radius: 10px; */
    color: #232129 !important;
  }

  .DayPicker-Weekday {
    color: #fff;
  }

  .DayPicker-Caption {
    color: #fff;
    background: #000;
    padding: 10px;
    border-radius: 5px;

    @media (max-width: 768px) {
      max-width: 90%;
      margin: 0 auto;
    }
  }

  .DayPicker-NavButton {
    filter: brightness(0) invert(1);
    margin-top: 13px;
  }

  .DayPicker-Caption select {
    border-radius: 5px;
    padding: 5px;
    background: #fff;
    margin-right: 7px;
    border: 0;
  }
`;


export const Row = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  width: 100%;

  form {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
  }

  &.row-buttons {
    justify-content: space-between;
    border-top: 1px solid #707070;
    margin-top: 20px;
    padding-top: 30px;

    > div {
      display: flex;

      @media(max-width: 769px) {
        width: 100%;
      }
    }

    button {
      width: 250px;

      @media(max-width: 769px) {
        width: 100%;
        margin: 0 !important;
      }

      &.next {
        background: #fff;
        border: 2px solid #181818;
        color: #181818;

        @media(max-width: 769px) {
          display: none;
        }
      }

      &.prev {
        background: #fff;
        border: 2px solid #a5a5a5;
        color: #a5a5a5;

        @media(max-width: 769px) {
          display: none;
        }
      }

      & + button {
        margin-left: 20px;
      }
    }
  }
`;

export const Col = styled.div`
  width: 25%;
  text-align: center;

  p {
    a {
      color: #fff;
      text-decoration: none;
    }
  }

  &:first-of-type {
    h2 {
      text-align: left !important;
    }
  }

  @media (max-width: 769px) {
    width: 100%;

    &:first-of-type {
      h2 {
        text-align: center !important;
      }
    }
  }

  > div {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    justify-content: space-between;
  }
`;
