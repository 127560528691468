import React, { useCallback, useRef, useState, useEffect } from 'react';
import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import * as Yup from 'yup';

import { useAuth } from '../../hooks/Auth';
import api from '../../services/api';
import { useToast } from '../../hooks/Toast';
import getValidationErrors from '../../utils/getValidationErrors';

import Logo from '../../assets/logo.png';

import Input from '../../components/Input';
import Button from '../../components/Button';

import { Container } from './styles';
import { Link, useHistory } from 'react-router-dom';

const ResetPassword: React.FC = () => {
  const formRef = useRef<FormHandles>(null);
  const [step, setStep] = useState(1);
  const [currentEmail, setCurrentEmail] = useState(null);
  const [verifying, setVerifying] = useState(false);
  const [reseting, setReseting] = useState(false);
  const [sendingEmail, setSendingEmail] = useState(false);

  const { addToast } = useToast();
  const history = useHistory();

  const handleSubmit = useCallback(
    async (data: any) => {
      try {
        formRef.current?.setErrors({});
        const schema = Yup.object().shape({
          email: Yup.string()
            .required('E-mail obrigatório')
            .email('Digite um e-mail válido'),
        });

        await schema.validate(data, {
          abortEarly: false,
        });

        setSendingEmail(true);

        await api
          .post('/users/reset-password', {
            email: data.email,
            type: 'email',
          })
          .then(res => {
            setSendingEmail(false);
            setCurrentEmail(data.email);
            setStep(2);
            addToast({
              type: 'success',
              title: 'Quase lá',
              description: `Um código foi enviado ao seu e-mail, use-o a seguir.`,
              timer: 9000,
            });
          });
      } catch (err) {
        setSendingEmail(false);
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);

          formRef.current?.setErrors(errors);

          return;
        } else {
          console.log(err);
          if (err.toString().includes('404')) {
            addToast({
              type: 'error',
              title: 'Ops!',
              description: `Nenhum usuário com este e-mail foi encontrado.`,
            });
          } else {
            addToast({
              type: 'error',
              title: 'Ops! Ocorreu um erro.',
              description: `${err.message}`,
            });
          }
        }
      }
    },
    [step, currentEmail],
  );

  const handleResetWithToken = useCallback(
    async (data: any) => {
      try {
        formRef.current?.setErrors({});
        const schema = Yup.object().shape({
          code: Yup.string().required('Código obrigatório'),
          new_password: Yup.string().required('Senha obrigatória'),
          confirm_new_password: Yup.string().oneOf(
            [Yup.ref('new_password')],
            'As senhas não conferem!',
          ),
        });

        await schema.validate(data, {
          abortEarly: false,
        });

        setVerifying(true);

        var response = await api.post('/users/reset-password/verify', {
          email: currentEmail,
          token: data.code,
          password: data.new_password,
        });

        setVerifying(false);
        setReseting(true);

        setReseting(false);
        addToast({
          type: 'success',
          title: 'Pronto!',
          description: `Sua senha foi recuperada com sucesso.`,
        });
        history.push(`${process.env.PUBLIC_URL}/login`);
      } catch (err) {
        setReseting(false);
        setVerifying(false);
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);

          formRef.current?.setErrors(errors);

          return;
        } else {
          console.log(err);
          if (err.toString().includes('401')) {
            addToast({
              type: 'error',
              title: 'Ops!',
              description: `As informações fornecidas não são válidas, tente novamente.`,
            });
          } else {
            addToast({
              type: 'error',
              title: 'Ops! Ocorreu um erro.',
              description: `${err.message}`,
            });
          }
        }
      }
    },
    [currentEmail, verifying, reseting],
  );

  return (
    <Container>
      <div className="box-login">
        <div className="logo-box">
          <img src={Logo} alt="Logo Shield Compliance" />
        </div>
        <div className="form-box">
          {step === 1 && (
            <Form onSubmit={handleSubmit} ref={formRef}>
              <label>Informe o e-mail</label>
              <Input
                type="email"
                name="email"
                placeholder="exemplo@exemplo.com.br"
                fullWidth
              />
              <p className="instructions">
                Um código será enviado ao seu e-mail.
              </p>
              <div className="buttons">
                <Button disabled={sendingEmail} type="submit">
                  {sendingEmail ? 'Enviando...' : 'Enviar'}
                </Button>

                <div className="sub-actions">
                  <button
                    className="forgot_pass"
                    type="button"
                    onClick={() => {
                      history.push(`${process.env.PUBLIC_URL}/login`);
                    }}
                  >
                    Login
                  </button>
                </div>
              </div>
            </Form>
          )}

          {step === 2 && (
            <Form onSubmit={handleResetWithToken} ref={formRef}>
              <label>Código de recuperação de senha</label>
              <Input
                type="text"
                name="code"
                placeholder="Informe o código"
                fullWidth
              />

              <label>Nova senha</label>
              <Input
                type="password"
                name="new_password"
                placeholder="******"
                fullWidth
              />

              <label>Confirme a nova senha</label>
              <Input
                type="password"
                name="confirm_new_password"
                placeholder="******"
                fullWidth
              />

              <div className="buttons">
                <div className="sub-actions">
                  <button
                    className="forgot_pass"
                    type="button"
                    onClick={() => setStep(1)}
                  >
                    Reenviar código
                  </button>
                  <button
                    className="forgot_pass"
                    type="button"
                    onClick={() => {
                      history.push(`${process.env.PUBLIC_URL}/login`);
                    }}
                  >
                    Login
                  </button>
                </div>
                <Button disabled={verifying || reseting} type="submit">
                  {verifying
                    ? 'Verificando...'
                    : reseting
                      ? 'Restaurando...'
                      : 'Restaurar'}
                </Button>
              </div>
            </Form>
          )}
        </div>
      </div>
    </Container>
  );
};

export default ResetPassword;
