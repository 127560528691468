import React, { useCallback, useEffect, useState } from 'react';
import { Document, Page, pdfjs } from 'react-pdf';
import { useParams } from 'react-router-dom';
import { useToast } from '../../hooks/Toast';
import { Project } from '../../interfaces/Project';
import api from '../../services/api';
import Container, { Loading, PDFContainer } from './styles';
import { saveAs } from 'file-saver';
import { FaDownload } from 'react-icons/fa';
import { IoMdArrowRoundBack, IoMdArrowRoundForward } from 'react-icons/io';
import { AiOutlineZoomIn, AiOutlineZoomOut } from 'react-icons/ai';
import LoadingGIF from '../../assets/loading.gif';

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const CustomerPdf: React.FC = () => {
  const [item, setItem] = useState<any>(null);

  const [numPages, setNumPages] = useState(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [pageNumber, setPageNumber] = useState(1);
  const [currentScale, setCurrentScale] = useState(0.8);
  const { id } = useParams<any>();
  const { addToast } = useToast();

  useEffect(() => {
    if (id) {
      setLoading(true);
      api.get(`projects/${id}/download`, { responseType: 'arraybuffer' }).then(res => {
        // api.get(`projects/${id}/get-file-signedurl`).then(res => {
        // console.log(res)
        setItem(res.data);
        setLoading(false);
      }).catch((err) => {
        setLoading(false);
        addToast({
          type: 'error',
          title: 'Oops!',
          description:
            'Solicitação inválida. Verifique os dados e tente novamente.',
          timer: 6000
        });
      });
    }
  }, [id]);

  function onDocumentLoadSuccess({ numPages }: any) {
    setNumPages(numPages);
  }

  const LoadingComponent = (
    <Loading>
      <div>
        <img src={LoadingGIF} />
        <h2>Aguarde um momento, estamos preparando o relatório...</h2>
      </div>
      <div>
        <p>Caso não carregue, tente novamente ou entre em contato com o suporte.</p>
        <a href="mailto:contato@shieldcompliance.com.br">contato@shieldcompliance.com.br</a>
      </div>
    </Loading>
  );

  const handleDownload = useCallback(async () => {
    var blob = new Blob([item], { type: "application/pdf;charset=utf-8" });
    saveAs(blob, `Relatório - Shield Compliance`);
  }, [item]);

  return (
    <Container>
      {
        loading ? (
          <Loading>
            <div>
              <img src={LoadingGIF} />
              <h2>Aguarde um momento, estamos preparando o relatório...</h2>
            </div>
            <div>
              <p>Caso não carregue, tente novamente ou entre em contato com o suporte.</p>
              <a href="mailto:contato@shieldcompliance.com.br">contato@shieldcompliance.com.br</a>
            </div>
          </Loading>
        ) : (
          item ? (
            <PDFContainer>
              <div className="numeracao">
                <p>
                  <div className="action-buttons">
                    <button type="button" className="previous" title="Menos zoom" onClick={() => setCurrentScale(currentScale - 0.2)} disabled={(currentScale - 0.3) < 0}><AiOutlineZoomOut size={22} /></button>
                    <button type="button" className="previous" title="Mais zoom" onClick={() => setCurrentScale(currentScale + 0.2)}><AiOutlineZoomIn size={22} /></button>

                    <button type="button" className="previous" title="Página anterior" onClick={() => setPageNumber(pageNumber - 1)} disabled={pageNumber === 1}><IoMdArrowRoundBack size={22} /></button>
                    <span>Página {pageNumber} de {numPages}</span>
                    <button type="button" className="next" title="Próxima página" onClick={() => setPageNumber(pageNumber + 1)} disabled={pageNumber === numPages}><IoMdArrowRoundForward size={22} /></button>
                    {/* <button type="button" className="download" title="Fazer download do arquivo" onClick={handleDownload}><FaDownload size={22} /></button> */}
                  </div>
                </p>
              </div>
              <Document
                file={{ data: item }}
                onLoadSuccess={onDocumentLoadSuccess}
                onLoadError={console.error}
                loading={LoadingComponent}
              >
                <Page pageNumber={pageNumber} scale={currentScale} />
              </Document>
            </PDFContainer>
          ) : (
            <p>Oops! Algo deu errado.</p>
          )
        )
      }
    </Container>
  );
}

export default CustomerPdf;
