import React, { useEffect, useState, useCallback } from 'react';
import { Container, Content, Footer, Main, NavBar } from './styles';
import Logo from '../../assets/logo.png';
import { FiArrowLeftCircle } from 'react-icons/fi';
import Step1 from './_step1';
import Step2 from './_step2';
import { Schedule } from '../../interfaces/Schedule';

const CustomSchedule: React.FC = () => {
  const [currentStep, setCurrentStep] = useState(1);
  const [formData, setFormData] = useState<Schedule>();

  const handleNext = useCallback((data) => {
    setFormData({ ...formData, ...data });
    setCurrentStep(currentStep + 1);
  }, [formData, currentStep]);

  return (
    <Main>
      <NavBar>
        <img src={Logo} width={120} />
        <a href="/"><FiArrowLeftCircle size={22} />VOLTAR PARA O SISTEMA</a>
      </NavBar>
      <Content>
        {
          currentStep === 1 && (
            <Step1 submit={handleNext} />
          )
        }
        {
          currentStep === 2 && (
            <Step2 goBack={() => setCurrentStep(1)} formData={formData} />
          )
        }
      </Content>

      <Footer>
        <Container>

        </Container>
      </Footer>
    </Main >
  );
};

export default CustomSchedule;
