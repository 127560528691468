import styled, { keyframes } from 'styled-components';

const appearFromTop = keyframes`
  from {
    opacity: 0;
    transform: translateY(-50px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
`;

export const Container = styled.div`
  display: flex;
  justify-content: space-between;
  overflow: hidden;

  @media (max-width: 768px) {
    flex-direction: column;
  }

  .content {
    flex: 1;
    padding: 80px 0;

    @media (max-width: 768px) {
      padding-top: 0;
    }

    .breadcrumb {
      display: flex;
      width: 100%;
      justify-content: space-between;
      margin-bottom: 20px;
      margin-top: 20px;
      align-items: center;
      flex-wrap: wrap;

      animation: ${appearFromTop} 1s;

      @media (max-width: 769px) {
        flex-direction: column;
        align-items: flex-start;
        margin: 25px 0 15px;
      }

      > div {
        display: flex;
        align-items: center;
        width: 100%;
        justify-content: space-between;

        @media (max-width: 769px) {
          width: 100%;
          padding-bottom: 10px;
          margin-bottom: 10px;
          border-bottom: 1px solid #dfdfdf;
          flex-direction: column;
          align-items: normal;
        }
      }

      h2 {
        font-size: 20px;
        color: #333;
        font-weight: 500;
        margin-right: 30px;

        & + h2 {
          margin-right: 0;
        }
      }

      form {
        > div {
          width: 300px;
          height: 40px;
          margin-bottom: 0;
          .react-select__multi-value__label {
            max-width: 60px;
          }

          .react-select__value-container--is-multi {
            max-height: 38px;
            overflow: auto;
          }
        }

        @media (max-width: 769px) {
          flex: 1;

          > div {
            width: 100%;
          }
        }
      }
    }

  }
`;
