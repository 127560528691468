import styled, { keyframes } from 'styled-components';
import { shade } from 'polished';

const appearFromTop = keyframes`
  from {
    opacity: 0;
    transform: translateY(-50px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
`;

export const Container = styled.div`
  padding: 0 50px;
  place-items: center;
  flex-direction: column;

  .filters {
    > div {
      width: auto;
    }
  }

  form {
    > div {
      margin-right: 20px;
      width: 300px;
    }
  }

  @media (max-width: 769px) {
    padding: 0 15px;
  }

  @media (max-width: 1320px) {
    .filters {
      flex-direction: column;

      form {
        width: 100%;
        justify-content: space-between;
        flex-direction: column;

        > div {
          margin-bottom: 10px;
          margin-left: 0;
          max-width: calc((100% / 3) - 5px);
          min-width: 0;
        }
      }

      > div {
        width: 100%;

        input {
          width: calc(100% - 106px);
        }
      }
    }
  }

  @media (max-width: 768px) {
    .filters form > div {
      /* max-width: calc(50% - 5px); */
      max-width: 100%;
    }
  }

  .form-field {
    width: 49%;
    position: relative;

    @media (max-width: 768px) {
      width: 100%;
    }

    & > div {
      max-width: 100% !important;
    }

    svg.whatsapp {
      color: #40c77c;
      position: absolute;
      right: 4px;
      top: 26px;
      font-size: 33px;
      cursor: pointer;

      &.inactive {
        color: #ddd !important;
        pointer-events: none;
        opacity: 0.5;
      }
    }
  }

  .sub-actions {
    margin: 25px 0;

    @media (max-width: 768px) {
      display: flex;
      justify-content: center;
    }

    button {
      display: flex;
      align-items: center;
      border: none;
      padding: 10px;
      border-radius: 5px;
      color: #fff;
      font-weight: 500;
      background: #181818;
      transition: 0.4s;
      box-shadow: 0 3px 6px rgb(0 0 0 / 12%);

      svg {
        font-size: 30px;
        color: #fff;
        cursor: pointer;
        margin-right: 10px;
      }

      &:hover {
        background: #eee;
        color: #181818;

        svg {
          color: #181818;
        }
      }
    }
  }
`;

export const Content = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100;

  @media (max-width: 1230px) {
    flex-direction: column-reverse;
    align-items: center;
  }
`;

export const Schedules = styled.div`
  flex: 1;
  @media (max-width: 1230px) {
    width: 100%;
  }

  .alert {
    background: #ddd;
    padding: 20px;
    border-radius: 10px;
    color: #333;
    font-weight: 500;
  }
`;

export const Appointment = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  padding: 0 15px;
  border-radius: 10px;

  background: #ff8787;

  &.Concluído {
    background: #ddd;
  }

  &.Pendente {
    background: #f9ffb8;
  }

  &.Confirmado {
    background: #c4f38e;
  }


  /* &:nth-child(odd) {
    background: #f3f9f9;
    border-radius: 15px;
  } */

  & + div {
    margin-top: 10px;
  }

  > span {
    margin-left: auto;
    display: flex;
    align-items: center;
    line-height: 18px;
    font-weight: 500;

    svg {
      margin-right: 8px;
      color: #333;
    }
  }

  > div {
    display: flex;
    flex-direction: column;
    flex: 1;
    align-items: center;
    padding: 17px;
    border-radius: 10px;
    margin-left: 24px;
    color: #313131;

    strong {
      color: #333;
    }

    @media (max-width: 1320px) {
      display: block;
    }

    > div {

      &.status {
        display: flex;
        flex-direction: column;
        align-items: center;
        padding-right: 24px;
        border-right: 1px solid #b0b8c5;
        width: 110px;

        @media (max-width: 1320px) {
          padding-right: 15px;
          width: 85px;
          float: left;
          height: 85px;
          justify-content: center;
        }
      }

      /* span {
        display: block;
        width: 35px;
        height: 35px;
        border-radius: 50%;
        background: #c53030;
      }

      &.Pendente span {
        background: #efff4e;
      }

      &.Confirmado span {
        background: #8bc34a;
      } */

      & + div {
        display: flex;
        flex-direction: column;
        margin-left: 24px;

        @media (max-width: 1320px) {
          padding-left: 20px;
        }

        & + div {
          padding-left: 24px;
          border-left: 1px solid #b0b8c5;

          @media (max-width: 1320px) {
            padding-left: 20px;
          }
        }
      }
    }
  }

  @media (max-width: 540px) {
    flex-direction: column;

    & + div {
      margin-top: 30px;
    }

    > span {
      margin: 0 0 10px;
    }

    > div {
      display: block;
      width: 100%;
      margin: 0;
    }
  }
`;

export const BoxCalendar = styled.div`
  width: 380px;
  max-width: 100%;
  margin-left: 30px;

  @media (max-width: 1230px) {
    margin-left: 0;
    margin-bottom: 30px;
  }

  .DayPicker {
    background: #181818;
    border-radius: 10px;
  }

  .DayPicker-wrapper {
    padding-bottom: 0;
  }

  .DayPicker,
  .DayPicker-Month {
    width: 100%;
  }

  .DayPicker-Month {
    border-collapse: separate;
    border-spacing: 8px;
    margin: 16px;
    width: calc(100% - 32px);
  }

  .DayPicker-Day {
    width: 40px;
    height: 40px;
  }

  .DayPicker-Day--available:not(.DayPicker-Day--outside) {
    background: #2b2b2b;
    border-radius: 10px;
    color: #fff;
  }

  .DayPicker:not(.DayPicker--interactionDisabled)
    .DayPicker-Day:not(.DayPicker-Day--disabled):not(.DayPicker-Day--selected):not(.DayPicker-Day--outside):hover {
    background: ${shade(0.2, '#2b2b2b')};
  }

  .DayPicker-Day--today {
    font-weight: normal;
  }

  .DayPicker-Day--disabled {
    color: #b4b4b4 !important;
    background: transparent !important;
  }

  .DayPicker-Day--selected {
    background: #fff !important;
    border-radius: 10px;
    color: #232129 !important;
  }

  .DayPicker-Weekday {
    color: #fff;
  }

  .DayPicker-Caption {
    color: #fff;
  }

  .DayPicker-NavButton {
    filter: brightness(0) invert(1);
  }

  .DayPicker-Caption select {
    border-radius: 5px;
    padding: 5px;
    background: #fff;
    margin-right: 7px;
    border: 0;
  }
`;

export const FormContainer = styled.div`
  padding: 0 50px;
  place-items: center;
  flex-direction: column;

  @media (max-width: 768px) {
    padding: 0 15px;
  }

  form {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    position: relative;
    background: #fff;
    padding: 30px;
    border-radius: 5px;
    margin-bottom: 30px;
    flex-direction: column;

    .close-btn {
      position: absolute;
      right: 0;
      top: -50px;
      padding: 0;
      line-height: 20px;
      background: transparent;
      border: 0;
      color: #181818;
    }

    .office-addres {
      width: 100%;
      padding: 15px 0;
      margin: 10px 0 30px;
      border-bottom: 1px solid #ddd;
      border-top: 1px solid #ddd;
      color: #333;

      h3 {
        font-size: 18px;
        margin-bottom: 6px;
      }
    }

    .form-field {
      width: 100%;
      max-width: calc(50% - 15px);

      display: flex;
      justify-content: space-between;
      align-items: flex-end;

      a {
        width: 40px;
        height: 40px;
        margin-bottom: 20px;
        margin-left: 5px;
        font-size: 28px;
        background: #25D366;
        border-radius: 5px;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }

    button {
      border-radius: 5px;

      &.finish {
        background-color: #c3c3c3;
        border-color: #c3c3c3;

        &:hover {
          color: #fff;
          background: ${shade(0.2, '#c3c3c3')};
        }

        &:disabled {
          cursor: not-allowed;
        }
      }

      &.confirm {
        background-color: #40c77c;
        border-color: #40c77c;

        &:hover {
          color: #fff;
          background: ${shade(0.2, '#40c77c')};
        }

        &:disabled {
          cursor: not-allowed;
          background-color: #ddd;
          border: 1px solid #ddd;
          color: #999;
        }
      }

      &.cancel {
        background-color: #c53030;
        border-color: #c53030;

        &:hover {
          color: #fff;
          background: ${shade(0.2, '#c53030')};
        }
      }
    }

    button + button {
      margin-right: 15px;
    }
  }

  .vaccination-people-list {
    width: 100%;
    /*display: flex;
    flex-direction: column;
    box-sizing: border-box;
    max-height: 350px;
    overflow-y: auto;
    overflow-x: hidden; */

    ::-webkit-scrollbar {
      width: 13px;
      height: 13px;
    }
    ::-webkit-scrollbar-thumb {
      background: #181818;
      border-radius: 10px;
    }
    ::-webkit-scrollbar-thumb:hover{
      background: #181818;
    }
    ::-webkit-scrollbar-track{
      background: #ffffff;
      border-radius: 10px;
      box-shadow: inset 7px 10px 12px #f0f0f0;
    }
  }

  .scope {
      padding: 20px;
      background: #F5F5F5;
      border-radius: 5px;
      /* margin-bottom: 25px; */
      position: relative;
      width: 100%;
      box-sizing: border-box;

      & + .scope {
        margin-top: 25px;
      }

      .rm {
        position: absolute;
        right: 10px;
        top: 0px;
        width: 30px;
        height: 30px;
        border-radius: 0 0 5px 5px;
        border: 0;
        background: #c53030;
        display: flex;
        justify-content: center;
        align-items: center;
        color: #fff;
        font-size: 22px;
        cursor: pointer;

        &:hover {
          background-color: #8f2222;
        }
      }

      .box-file {
        padding: 8px;
        height: 38px;
        background: #ffffff;
        margin-top: 20px;
        border-radius: 5px;
        cursor: pointer;
        position: relative;

        label {
          cursor: pointer;
          width: 100%
        }

        .input {
          background-color: transparent;
          position: absolute;
          right: 0;
          top: 0;
          width: 100%;
          pointer-events: none;

          > div {
            pointer-events: all;
          }

          input {
            visibility: hidden;
          }
        }

        p {
          svg {
            color: #181818;
          }
        }

        svg {
          float: left;
          margin-right: 7px;
          color: #181818;
        }

        div {
          > div {
            /* display: none; */
          }
        }

      }
    }

    button.new-btn {
      display: inline-flex;
      margin-top: 25px;
      margin-bottom: 20px;
      background: transparent;
      border: none !important;
      color: #181818;
      cursor: pointer;
      align-items: center;
      font-size: 20px !important;


      svg {
        background-color: #181818;
        color: #fff;
        font-size: 30px;
        padding: 5px;
        margin-right: 10px;
        border-radius: 3px;
        font-weight: 600;
      }
    }

    h2.subtitle {
      color: #181818;
      margin-bottom: 15px;
    }

    .delete-file {
      /* padding-left: 52%; */
      display: flex;
      width: 100%;
      align-items: center;
      justify-content: flex-end;

      > div, a {
        height: 45px;
        display: flex;
        align-items: center;
        margin-left: 15px;
        box-sizing: border-box;
        background: #ddd;
        padding: 10px;
        border-radius: 5px;

        &:hover {
          background: #eee;
          cursor: pointer;

          a, p, svg {
            color: #f10;
          }

          &.view-file {
            text-decoration: none;

            p, a, svg {
              color: #4a90e2 !important;
              display: flex;
              align-items: center;
            }
          }
        }

        &.view-file {
          text-decoration: none;

          p, a, svg {
            display: flex;
            align-items: center;
          }
        }

        a, p  {
          color: #313131;
          text-decoration: none;
          font-weight: 500;
          font-size: 13px;
        }

        svg {
          margin-left: 5px;
        }
      }

      @media (max-width: 768px) {
        padding: 0;
        flex-direction: column;

        > div {
          width: 100%;
          margin: 0;
          margin-bottom: 10px;
          justify-content: center;
        }
      }
    }
`;



export const Row = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;

  &.row-buttons {
    justify-content: space-between;
    border-top: 1px solid #707070;
    margin-top: 20px;
    padding-top: 30px;

    > div {
      display: flex;

      @media(max-width: 769px) {
        width: 100%;
      }
    }

    button {
      width: 250px;

      @media(max-width: 769px) {
        width: 100%;
        margin: 0 !important;
      }

      &.next {
        background: #fff;
        border: 2px solid #181818;
        color: #181818;

        @media(max-width: 769px) {
          display: none;
        }
      }

      &.prev {
        background: #fff;
        border: 2px solid #a5a5a5;
        color: #a5a5a5;

        @media(max-width: 769px) {
          display: none;
        }
      }

      & + button {
        margin-left: 20px;
      }
    }
  }

  .dzu-dropzone {
    border: 1px solid #ccc;
    margin-bottom: 30px;
  }

  .dzu-inputLabel {
    color: #181818;
  }

  .dzu-previewButton {
    filter: none;
  }

  span.dzu-previewFileName, .dzu-inputLabelWithFiles {
    color: #181818;
  }

  .all-files {
    width: 100%;
    max-height: 300px;
    overflow-y: auto;
    border-radius: 5px;
    margin: 15px 0;

    .actions-of-files {
      display: flex;
      width: 100%;
      align-items: center;
      justify-content: space-between;
      background: #fff;
      padding: 10px 20px;

      &:nth-child(2n + 1) {
        background-color: #f5f5f5;
      }

      .actions {
        display: flex;

        > div, a {
          height: 45px;
          display: flex;
          align-items: center;
          margin-left: 15px;
          box-sizing: border-box;
          background: #ddd;
          padding: 10px;
          border-radius: 5px;
          text-decoration: none;

          &.view-file {
            text-decoration: none;

            p, a, svg {
              display: flex;
              align-items: center;
            }
          }

          a, p  {
            color: #313131;
            text-decoration: none;
            font-weight: 500;
            font-size: 13px;
          }

          svg {
            margin-left: 5px;
          }

          &:hover {
            background: #eee;
            cursor: pointer;

            a, p, svg {
              color: #f10;
            }

            &.view-file {
              text-decoration: none;

              p, a, svg {
                color: #4a90e2 !important;
                display: flex;
                align-items: center;
              }
            }
          }
        }
      }
    }

    ::-webkit-scrollbar {
      width: 13px;
      height: 13px;
    }
    ::-webkit-scrollbar-thumb {
      background: #181818;
      border-radius: 10px;
    }
    ::-webkit-scrollbar-thumb:hover{
      background: #181818;
    }
    ::-webkit-scrollbar-track{
      background: #ffffff;
      border-radius: 10px;
      box-shadow: inset 7px 10px 12px #f0f0f0;
    }

    p {
      text-align: left;
      margin: 5px 0;
    }
  }
`;

export const Col = styled.div`
  width: calc(50% - 15px);

  @media (max-width: 769px) {
    width: 100%;
  }

  > div {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    justify-content: space-between;
  }
`;
