import React, { useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import {
  FiHome,
  FiUsers,
  FiCalendar,
  FiLogOut,
  FiMenu,
  FiX,
  FiSettings,
} from 'react-icons/fi';
import { AiOutlineFundProjectionScreen, AiOutlineSchedule } from 'react-icons/ai';
import { VscOrganization } from 'react-icons/vsc';
import { FiPhoneCall } from 'react-icons/fi';

import { useAuth } from '../../hooks/Auth';

import { Container } from './styles';

import Logo from '../../assets/logo.png';

const Menu: React.FC = () => {
  const [menuOpen, setMenuOpen] = useState(false);
  const [currentPath, setCurrentPath] = useState('');

  const { signOut, user } = useAuth();
  const location = useLocation();

  useEffect(() => {
    if (location) {
      setCurrentPath(location.pathname);
    }
  }, [location]);

  return (
    <Container>
      <div className="fixed">
        <div className="logo">
          <img src={Logo} alt="Logo Shield Compliance" />
        </div>
        <div className="menu">
          <button
            type="button"
            className="toogle-menu"
            onClick={() => setMenuOpen(!menuOpen)}
          >
            {!menuOpen ? <FiMenu size={20} /> : <FiX />}
          </button>
          <ul className={menuOpen ? `open` : ``}>
            {user.role_id === 1 && (
              <>
                <li className={currentPath === '/' ? 'active' : ''}>
                  <Link to={`${process.env.PUBLIC_URL}/`}>
                    <FiHome size={22} />
                    Dashboard
                  </Link>
                </li>
                <li className={currentPath.includes('/usuarios') ? 'active' : ''}>
                  <Link to={`${process.env.PUBLIC_URL}/usuarios`}>
                    <FiUsers size={22} />
                    Usuários
                  </Link>
                </li>

                <li className={currentPath.includes('/organizacoes') ? 'active' : ''}>
                  <Link to={`${process.env.PUBLIC_URL}/organizacoes`}>
                    <VscOrganization size={22} />
                    Organizações
                  </Link>
                </li>
                <li className={currentPath.includes('/projetos') ? 'active' : ''}>
                  <Link to={`${process.env.PUBLIC_URL}/projetos`}>
                    <AiOutlineFundProjectionScreen size={22} />
                    Projetos
                  </Link>
                </li>
                <li className={''}>
                  <a href="https://siscompliance.com.br/admin/#/login" target="_blank">
                    <FiPhoneCall size={22} />
                    Denúncia
                  </a>
                </li>
                <li className={currentPath.includes('/agenda') ? 'active' : ''}>
                  <Link to={`${process.env.PUBLIC_URL}/agenda`}>
                    <FiCalendar size={22} />
                    Agendamentos
                  </Link>
                </li>
              </>
            )}

            {user.role_id === 2 && (
              <>
                <li className={currentPath.includes(`/meu-dashboard`) ? 'active' : ''}>
                  <Link to={`${process.env.PUBLIC_URL}/meu-dashboard`}>
                    <FiHome size={22} />
                    Meu dashboard
                  </Link>
                </li>

                <li className={currentPath.includes(`/meus-projetos/${user.id}`) ? 'active' : ''}>
                  <Link to={`${process.env.PUBLIC_URL}/meus-projetos/${user.id}`}>
                    <FiUsers size={22} />
                    Projetos
                  </Link>
                </li>

                {
                  user.schedule_pci ?
                    (
                      <>
                        <li className={currentPath.includes('/pci/agendamentos') ? 'active' : ''}>
                          <Link to={`${process.env.PUBLIC_URL}/pci/agendamentos`}>
                            <FiCalendar size={22} />
                            Agendar PCI
                          </Link>
                        </li>
                        <li className={currentPath.includes(`/meus-agendamentos/${user.id}`) ? 'active' : ''}>
                          <Link to={`${process.env.PUBLIC_URL}/meus-agendamentos/${user.id}`}>
                            <AiOutlineSchedule size={22} />
                            Agendamentos
                          </Link>
                        </li>
                      </>
                    ) : ""
                }

                <li className={currentPath.includes(`/meu-perfil/${user.id}`) ? 'active' : ''}>
                  <Link to={`${process.env.PUBLIC_URL}/meu-perfil/${user.id}`}>
                    <FiUsers size={22} />
                    Meu perfil
                  </Link>
                </li>
              </>
            )}

            {user.role_id === 1 && (
              <li className={currentPath.includes('/configuracoes') ? 'active' : ''}>
                <Link to={`${process.env.PUBLIC_URL}/configuracoes`}>
                  <FiSettings size={22} />
                    Configurações
                  </Link>
              </li>
            )}

            <li>
              <a href="/" onClick={() => signOut()}>
                <FiLogOut size={22} />
                Sair
              </a>
            </li>
          </ul>
        </div>
      </div>
    </Container>
  );
};

export default Menu;
