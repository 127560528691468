import React, { useState, useRef, useCallback, useEffect, useMemo } from 'react';
import { Form } from '@unform/web';
import { FormHandles } from '@unform/core';
import { FiXCircle } from 'react-icons/fi';
import { useParams, useHistory } from 'react-router-dom';

import * as Yup from 'yup';
import getValidationErrors from '../../utils/getValidationErrors';

import { useToast } from '../../hooks/Toast';

import api from '../../services/api';

import Input from '../../components/Input';
import Button from '../../components/Button';
import Select from '../../components/Select';

import { BoxCalendar, Col, FormContainer, Row } from './styles';
import Main from '../../components/Main';

import { User } from '../../interfaces/User';
import moment from 'moment';
import DatePicker from '../../components/DatePicker';
import DayPicker, { DateUtils } from 'react-day-picker';
import { confirmAlert } from 'react-confirm-alert';


const Configurations: React.FC = () => {
  const formRef = useRef<FormHandles>(null);

  const [weekDays] = useState([
    { value: 0, label: "Domingo" },
    { value: 1, label: "Segunda-feira" },
    { value: 2, label: "Terça-feira" },
    { value: 3, label: "Quarta-feira" },
    { value: 4, label: "Quinta-feira" },
    { value: 5, label: "Sexta-feira" },
    { value: 6, label: "Sábado" },
  ]);
  const [currentItem, setCurrentItem] = useState<any>();
  const [loading, setLoading] = useState<boolean>(false);
  const [sending, setSending] = useState<boolean>(false);
  const [reseting, setReseting] = useState<boolean>(false);
  const [refresh, setRefresh] = useState<boolean>(false);
  const [currentMonth, setCurrentMonth] = useState(new Date());
  const [configuredDays, setConfiguredDays] = useState<any[]>([]);
  const [allMonthConfigurations, setAllMonthConfigurations] = useState<any[]>([]);

  const history = useHistory();
  const { user_id } = useParams<any>();

  const { addToast } = useToast();

  const [selectedDays, setSelectedDays] = useState<any>([]);

  var handleDayClick = useCallback((day: any, { selected }: any) => {
    if (selected) {
      const selectedIndex = selectedDays.findIndex((selectedDay: any) =>
        DateUtils.isSameDay(selectedDay, day)
      );
      selectedDays.splice(selectedIndex, 1);
    } else {
      selectedDays.push(day);
    }

    setSelectedDays([...selectedDays]);
  }, [selectedDays]);

  useEffect(() => {
    setLoading(true);
    api
      .get(`configured-days`, {
        params: {
          year: moment(currentMonth).format('YYYY'),
          month: moment(currentMonth).format('MM'),
        }
      })
      .then(response => {
        const aux = response.data.success.items;

        const configuredDaysAux = aux?.map((conf: any) => {
          return moment(conf.date).format('D');
        });

        setConfiguredDays([...configuredDaysAux]);
        setAllMonthConfigurations([...aux]);
        setLoading(false);
      })
      .catch(err => {
        setLoading(false);
        console.log(err);
        addToast({
          type: 'error',
          title: 'Oops!',
          description:
            err.response?.data?.error?.error_message || "Ocorreu um erro, tente novamente.",
        });
      });
  }, [addToast, user_id, currentMonth, refresh]);

  const handleSubmit = useCallback(
    async (data: any, { reset }) => {
      setSending(true);
      try {
        formRef.current?.setErrors({});

        if (selectedDays.length === 0) {
          addToast({
            type: 'error',
            title: 'Atenção!',
            description:
              'Selecione pelo menos uma data do calendário.',
            timer: 6000
          });

          setSending(false);
          return;
        }

        data.dates = selectedDays;

        if (data.morning_start_time === "" && data.evening_start_time === "") {
          addToast({
            type: 'error',
            title: 'Atenção!',
            description:
              'Informe os horários de atendimento.',
            timer: 6000
          });

          setSending(false);
          return;
        }

        let schema = Yup.object().shape({
          morning_start_time: data.morning_end_time && !!data.morning_end_time ?
            Yup.string().required('Campo obrigatório (MANHÃ)') : Yup.string().nullable(),
          morning_end_time: data.morning_start_time && !!data.morning_start_time ?
            Yup.string().required('Campo obrigatório (MANHÃ)') : Yup.string().nullable(),
          evening_start_time: data.evening_end_time && !!data.evening_end_time ?
            Yup.string().required('Campo obrigatório (TARDE)') : Yup.string().nullable(),
          evening_end_time: data.evening_start_time && !!data.evening_start_time ?
            Yup.string().required('Campo obrigatório (TARDE)') : Yup.string().nullable(),
          interval_minutes: Yup.string().required('Campo obrigatório'),
        });

        await schema.validate(data, {
          abortEarly: false,
        });

        if (currentItem) {
          await api.put(`configurations`, data);
        } else {
          await api.post('configurations', data);
        }

        addToast({
          type: 'success',
          title: `Pronto!`,
          description: `Configurações da agenda foram ${currentItem ? 'atualizadas' : 'criadas'} com sucesso.`,
        });
        setSending(false);
        setRefresh(!refresh);
        setSelectedDays([]);
        reset();
      } catch (err) {
        setSending(false);
        console.log(err);
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);

          formRef.current?.setErrors(errors);
        } else {
          addToast({
            type: 'error',
            title: 'Falha na requisição',
            description:
              err.response ? err.response.data.error.error_message : 'Ocorreu um erro ao salvar, verifique os dados e tente novamente.',
          });
        }
      }
    },
    [currentItem, addToast, history, sending, selectedDays],
  );

  const handleReset = useCallback(() => {
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <div className="custom-ui">
            <h1>Confirmação</h1>
            <p>Gostaria de resetar as configurações do(s) dia(s) selecionado(s)?</p>
            <button onClick={onClose}>Não</button>
            <button
              onClick={async () => {
                setReseting(true);
                await api.post(
                  `configurations/reset`,
                  { dates: selectedDays },
                );

                addToast({
                  type: 'success',
                  title: 'Configurações resetadas com sucesso!',
                });

                setReseting(false);

                setRefresh(!refresh);
                setSelectedDays([]);

                onClose();
              }}
            >
              Sim, confirmar!
            </button>
          </div>
        );
      },
    });
  }, [reseting, selectedDays]);

  const handleMonthChange = useCallback((month: Date) => {
    setCurrentMonth(month);
  }, []);

  const disabledDays = useMemo(() => {
    const countDays = moment().daysInMonth();
    const dates: Date[] = [];
    const year = currentMonth.getFullYear();
    const month = currentMonth.getMonth();

    for (let i = 1; i <= countDays; i++) {
      if (!configuredDays.includes(i.toString())) {
        dates.push(new Date(year, month, i));
      }
    }

    return dates;
  }, [currentMonth, configuredDays]);

  return (
    <Main>
      <FormContainer>
        <div className="breadcrumb">
          <h2>Início / Configurações</h2>
        </div>

        <Form
          onSubmit={handleSubmit}
          ref={formRef}
          initialData={currentItem ? currentItem : undefined}
        >
          <button
            type="button"
            onClick={() => history.go(-1)}
            className="close-btn"
          >
            <FiXCircle size={30} />
          </button>

          <div className="all-configurations">
            <h2>Confira a configuração de horários deste mês</h2>
            {
              loading ? <p className="alert">Carregando...</p> :
                allMonthConfigurations.length === 0 ? (
                  <p className="alert">Nenhuma configuração encontrada para este mês.</p>
                ) : (
                  <Row>
                    {allMonthConfigurations.map((conf) => (
                      <Row>
                        <Col>
                          <p><strong>Data: </strong><br /> {moment(conf.date).format('DD/MM/YYYY')}</p>
                        </Col>
                        <Col>
                          <p><strong>Manhã: </strong><br /> {conf.morning_start_time} - {conf.morning_end_time}</p>
                        </Col>
                        <Col>
                          <p><strong>Tarde: </strong><br /> {conf.evening_start_time} - {conf.evening_end_time}</p>
                        </Col>
                        <Col>
                          <p><strong>Interv.: </strong><br /> {conf.interval_minutes} min.</p>
                        </Col>
                      </Row>
                    ))}
                  </Row>
                )
            }
          </div>

          <BoxCalendar>
            <h2>Configure sua agenda</h2>

            <DayPicker
              weekdaysShort={['D', 'S', 'T', 'Q', 'Q', 'S', 'S']}
              fromMonth={new Date()}
              selectedDays={selectedDays}
              onDayClick={handleDayClick}
              onMonthChange={handleMonthChange}
              disabledDays={[...disabledDays]}
              months={[
                'Janeiro',
                'Fevereiro',
                'Março',
                'Abril',
                'Maio',
                'Junho',
                'Julho',
                'Agosto',
                'Setembro',
                'Outubro',
                'Novembro',
                'Dezembro',
              ]}
            />
          </BoxCalendar>

          {/* <Select
            name="days"
            options={weekDays}
            isMulti
            isSearchable={false}
            isClearable={false}
            label="Dias de atendimento"
            placeholder="Selecionar..."
          /> */}
          <Row>
            <Input
              name="morning_start_time"
              label="Horário início (MANHÃ)"
              type="time"
            />
            <Input
              name="morning_end_time"
              label="Horário fim (MANHÃ)"
              type="time"
            />
            <Input
              name="evening_start_time"
              label="Horário início (TARDE)"
              type="time"
            />
            <Input
              name="evening_end_time"
              label="Horário fim (TARDE)"
              type="time"
            />
            <Input
              name="interval_minutes"
              label="Intervalo entre atendimentos (em minutos)"
              type="number"
            />
          </Row>

          <Row>
            <div className="break-line" />
            <div className="buttons">
              <Button type="button" className="prev" disabled={reseting || selectedDays.length === 0} onClick={handleReset}>{reseting ? "Resetando..." : "Resetar"}</Button>
              <Button type="submit" className="next" disabled={sending}>{sending ? "Salvando..." : "Salvar"}</Button>
            </div>
          </Row>
        </Form>
      </FormContainer>
    </Main>
  );
};

export default Configurations;
