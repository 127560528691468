import React from 'react';
import { Router, Switch } from 'react-router-dom';

import Route from './Route';

import Login from '../pages/Login';
import LoginCode from '../pages/LoginCode';

import Dashboard from '../pages/Dashboard';
import Users from '../pages/Users';
import UserForm from '../pages/Users/form';
import Organizations from '../pages/Organizations';
import OrganizationForm from '../pages/Organizations/form';
import Projects from '../pages/Projects';
import ProjectForm from '../pages/Projects/form';
import Calendar from '../pages/Calendar';
import CalendarForm from '../pages/Calendar/form';
import ResetPassword from '../pages/ResetPassword';
import CustomSchedule from '../pages/CustomSchedule';
import Configurations from '../pages/Configurations';
import MyProfile from '../pages/MyProfile';
import CustomerProjects from '../pages/CustomerProjects';
import CustomerSchedules from '../pages/CustomerSchedules';
import CustomerPdf from '../pages/CustomerPdf';
import CustomerDashboard from '../pages/CustomerDashboard';

const Routes: React.FC = () => (
  <Switch>
    <Route path={`${process.env.PUBLIC_URL}/login`} component={Login} />
    <Route path={`${process.env.PUBLIC_URL}/codigo`} component={LoginCode} />

    <Route
      path={`${process.env.PUBLIC_URL}/`}
      component={Dashboard}
      exact
      isPrivate
      isAdmin
    />
    <Route
      path={`${process.env.PUBLIC_URL}/meu-dashboard`}
      component={CustomerDashboard}
      exact
      isPrivate
    />
    <Route
      path={`${process.env.PUBLIC_URL}/usuarios`}
      component={Users}
      isPrivate
      isAdmin
      exact
    />
    <Route
      path={`${process.env.PUBLIC_URL}/usuarios/:user_id`}
      component={UserForm}
      isPrivate
      isAdmin
    />
    <Route
      path={`${process.env.PUBLIC_URL}/usuarios/novo`}
      component={UserForm}
      isPrivate
      isAdmin
    />
    <Route
      path={`${process.env.PUBLIC_URL}/organizacoes`}
      component={Organizations}
      isPrivate
      isAdmin
      exact
    />
    <Route
      path={`${process.env.PUBLIC_URL}/organizacoes/:id`}
      component={OrganizationForm}
      isPrivate
      isAdmin
    />
    <Route
      path={`${process.env.PUBLIC_URL}/organizacoes/novo`}
      component={OrganizationForm}
      isPrivate
      isAdmin
    />
    <Route
      path={`${process.env.PUBLIC_URL}/projetos`}
      component={Projects}
      isPrivate
      isAdmin
      exact
    />
    <Route
      path={`${process.env.PUBLIC_URL}/projetos/:id`}
      component={ProjectForm}
      isPrivate
      isAdmin
    />
    <Route
      path={`${process.env.PUBLIC_URL}/projetos/novo`}
      component={ProjectForm}
      isPrivate
      isAdmin
    />
    <Route
      path={`${process.env.PUBLIC_URL}/agenda`}
      component={Calendar}
      isPrivate
      isAdmin
      exact
    />
    <Route
      path={`${process.env.PUBLIC_URL}/agenda/:appointment_id`}
      component={CalendarForm}
      isPrivate
      isAdmin
    />
    <Route
      path={`${process.env.PUBLIC_URL}/agenda/novo`}
      component={CalendarForm}
      isPrivate
      isAdmin
    />
    <Route
      path={`${process.env.PUBLIC_URL}/configuracoes`}
      component={Configurations}
      isPrivate
      isAdmin
    />

    <Route
      path={`${process.env.PUBLIC_URL}/recuperar-senha`}
      component={ResetPassword}
    />
    <Route
      path={`${process.env.PUBLIC_URL}/pci/agendamentos`}
      component={CustomSchedule}
      isPrivate
      onlyAuthorizedUsers
    />

    <Route
      path={`${process.env.PUBLIC_URL}/meu-perfil/:user_id`}
      component={MyProfile}
      isPrivate
    />
    <Route
      path={`${process.env.PUBLIC_URL}/meus-projetos/:user_id`}
      component={CustomerProjects}
      isPrivate
    />
    <Route
      path={`${process.env.PUBLIC_URL}/meus-agendamentos/:user_id`}
      component={CustomerSchedules}
      isPrivate
    />
    <Route
      path={`${process.env.PUBLIC_URL}/relatorio/:id`}
      component={CustomerPdf}
      isPrivate
    />
  </Switch>
);

export default Routes;
