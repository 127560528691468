import React, { useState, useRef, useCallback, useEffect } from 'react';
import { Form } from '@unform/web';
import { FormHandles } from '@unform/core';
import { FiXCircle } from 'react-icons/fi';
import { useParams, useHistory } from 'react-router-dom';

import * as Yup from 'yup';
import getValidationErrors from '../../utils/getValidationErrors';

import { useToast } from '../../hooks/Toast';

import api from '../../services/api';

import Input from '../../components/Input';
import Button from '../../components/Button';
import Select from '../../components/Select';
import InputMask from '../../components/InputMask';

import { FormContainer } from './styles';
import Main from '../../components/Main';

import { User } from '../../interfaces/User';
import InputPassword from '../../components/InputPassword';
import moment from 'moment';

interface UserFormData extends User {
  password: string;
}

const FormModal: React.FC = () => {
  const formRef = useRef<FormHandles>(null);

  const [phoneMask, setPhoneMask] = useState('(99) 9999-9999?');
  const [user, setUser] = useState<User | null>();
  const [sending, setSending] = useState<boolean>(false);

  const history = useHistory();
  const { user_id } = useParams<any>();

  const { addToast } = useToast();

  useEffect(() => {
    if (user_id && user_id !== 'novo') {
      api
        .get(`users/${user_id}`)
        .then(response => {
          const userAux = response.data.item;

          if (userAux.phone?.length >= 15) {
            setPhoneMask('(99) 99999-9999');
          } else {
            setPhoneMask('(99) 9999-9999?');
          }

          setUser({ ...userAux });
          formRef.current?.setData({
            ...userAux,
            phone: userAux.phone || '',
            schedule_pci: userAux.schedule_pci ? { value: true, label: "Sim" } : { value: false, label: "Não" },
            active: userAux.active ? { value: true, label: "Ativa" } : { value: false, label: "Inativa" },
            role_id: { value: userAux.role.id, label: userAux.role.name }
          });
        })
        .catch(err => {
          console.log(err);
          addToast({
            type: 'error',
            title: 'Falha na requisição',
            description:
              'Ocorreu um erro ao carregar os dados, verifique sua conexão e tente novamente.',
          });
        });
    }
  }, [addToast, user_id]);

  const handleSubmit = useCallback(
    async (data: UserFormData) => {
      setSending(true);
      try {
        formRef.current?.setErrors({});

        let schema = Yup.object().shape({
          name: Yup.string().required('Nome obrigatório'),
          role_id: Yup.string().required('Permissão obrigatória'),
          email: Yup.string()
            .email('Digite um e-mail válido')
            .required('E-mail obrigatório'),
          phone: Yup.string().required('Telefone obrigatório'),
          password: user ? Yup.string() : Yup.string().required('Senha obrigatória'),
          active: Yup.string().required('Status de ativação obrigatório'),
          schedule_pci: Yup.string().required('Permissão de agendamento obrigatória'),
        });

        await schema.validate(data, {
          abortEarly: false,
        });

        let response;
        if (user) {
          response = await api.put(`users/${user.id}`, data);
        } else {
          response = await api.post('users', data);
        }
        addToast({
          type: 'success',
          title: `Cadastro ${user ? 'atualizado' : 'realizado'}`,
          description: response.data.success.message,
        });

        history.go(-1);
        setSending(false);
      } catch (err) {
        setSending(false);
        console.log(err);
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);

          formRef.current?.setErrors(errors);
        } else {
          addToast({
            type: 'error',
            title: 'Falha na requisição',
            description:
              'Ocorreu um erro ao salvar, verifique os dados e tente novamente',
          });
        }
      }
    },
    [user, addToast, history, sending],
  );

  return (
    <Main>
      <FormContainer>
        <div className="breadcrumb">
          <h2>Início / {user ? 'Editar' : 'Novo'} usuário</h2>
        </div>

        <Form
          onSubmit={handleSubmit}
          ref={formRef}
          initialData={user ? user : undefined}
        >
          <button
            type="button"
            onClick={() => history.go(-1)}
            className="close-btn"
          >
            <FiXCircle size={30} />
          </button>

          <Input name="name" label="Nome" />
          <Input name="email" label="E-mail" />
          <InputPassword name="password" label="Senha" />
          <InputMask
            name="phone"
            label="Telefone"
            mask={phoneMask}
            formatChars={{ '9': '[0-9]', '?': '[0-9 ]' }}
            maskChar={null}
            onChange={(event: any) => {
              if (event.target.value.length >= 15) {
                setPhoneMask('(99) 99999-9999');
              } else {
                setPhoneMask('(99) 9999-9999?');
              }
            }}
          />
          <Select
            name="role_id"
            options={[
              { value: 1, label: "Administrador" },
              { value: 2, label: "Cliente" },
            ]}
            isMulti={false}
            isSearchable={false}
            isClearable={false}
            label="Permissão"
            placeholder="Selecione a permissão"
          />
          <Select
            name="schedule_pci"
            options={[
              { value: true, label: "Sim" },
              { value: false, label: "Não" },
            ]}
            isMulti={false}
            isSearchable={false}
            isClearable={false}
            label="Pode agendar PCI? (Sim ou não)"
            placeholder="Selecionar..."
          />
          <Input
            name="expiration_date"
            label="Data de expiração (opcional)"
            type="date"
            min={moment().format('YYYY-MM-DD')}
          />
          <Select
            name="active"
            options={[
              { value: true, label: "Ativa" },
              { value: false, label: "Inativa" },
            ]}
            isMulti={false}
            isSearchable={false}
            isClearable={false}
            label="Status de ativação da conta"
            placeholder="Selecionar..."
          />
          <div className="break-line" />
          <div className="buttons">
            <Button type="submit" disabled={sending}>{sending ? "Salvando..." : "Salvar"}</Button>
          </div>
        </Form>
      </FormContainer>
    </Main>
  );
};

export default FormModal;
