import userEvent from '@testing-library/user-event';
import React, { useCallback } from 'react';

import { Container } from './styles';

import UserAvatar from '../../assets/user.png';
import { useAuth } from '../../hooks/Auth';

const Top: React.FC = () => {

  const { user } = useAuth();

  const initialName = useCallback(() => {
    if (user) {
      const fullName = user?.name.split(' ');

      const initials = fullName.length > 1 ? fullName[0]?.charAt(0) + fullName[1]?.charAt(0):user?.name.charAt(0);
      return initials.toUpperCase();
    }
  }, [user])

  return (
    <Container>
      <div className="left">
        <div className="userAvatar">
          <div className="avatar">
            {initialName()}
          </div>
        </div>
        <h3>Bem vindo, {user.name}</h3>
      </div>
    </Container>
  );
};

export default Top;
