import styled, { keyframes } from 'styled-components';
import { shade } from 'polished';

const appearFromTop = keyframes`
  from {
    opacity: 0;
    transform: translateY(-50px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
`;

export const Container = styled.div`
  padding: 0 50px;
  place-items: center;
  flex-direction: column;

  @media (max-width: 769px) {
    padding: 0 15px;
  }
`;

export const FormContainer = styled.div`
  padding: 0 50px;
  place-items: center;
  flex-direction: column;

  @media (max-width: 768px) {
    padding: 0 15px;
  }

  @media (max-width: 769px) {
    padding: 0 15px;
  }

  form {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    position: relative;
    background: #fff;
    padding: 30px;
    border-radius: 5px;
    margin-bottom: 30px;

    .close-btn {
      position: absolute;
      right: 0;
      top: -50px;
      padding: 0;
      line-height: 20px;
      background: transparent;
      border: 0;
      color: #181818;
    }

    button[type='submit'] {
      border-radius: 5px;
    }

    button {
      border-radius: 5px;
      /* max-width: 48%; */

      &.confirm {
        background-color: #40c77c;
        border-color: #40c77c;

        &:hover {
          color: #fff;
          background: ${shade(0.2, '#40c77c')};
        }
      }

      &.cancel {
        background-color: #c53030;
        border-color: #c53030;

        &:hover {
          color: #fff;
          background: ${shade(0.2, '#c53030')};
        }
      }
    }

    .to-right {
      @media (max-width: 769px) {
        /* max-width: 48%; */

        button {
          max-width: 100%;
          width: 237px;
        }
      }
    }

    button + button {
      margin-right: 15px;
    }

    .attendance-days {
      width: 100%;
      margin-bottom: 30px;
      padding-bottom: 30px;
      border-bottom: 1px solid #cfcfcf;

      label {
        font-weight: 500;
        margin-top: 10px;
        margin-bottom: 10px;
        display: block;
        color: #505050;
        font-size: 18px;
      }

      .new-day {
        display: flex;
        width: 100%;
        justify-content: space-between;
        align-items: flex-end;
        flex-wrap: wrap;
        /* border: 2px dashed #f1f1f1; */
        background: #f7f7f7;
        border-radius: 5px;
        padding: 25px;
        margin: 25px 0;

        @media (max-width: 769px) {
          flex-wrap: wrap;
          padding-top: 20px;

          & + .new-day {
            border-top: 1px solid #ddd;
          }
        }

        /* > div:nth-child(1),
        > div:nth-child(2) {
          max-width: calc(50% / 2 - 15px);
          width: 100%;

          @media (max-width: 769px) {
            max-width: 100%;
          }
        }

        &.vaccine {
          > div:nth-child(1),
          > div:nth-child(2) {
            max-width: calc(100% / 2 - 45px);
            width: 100%;

            @media (max-width: 769px) {
              max-width: 100%;
            }
          }
        } */

        /* .label {
          max-width: calc((50% - 15px) / 2 - 10px - 25px);

          @media (max-width: 769px) {
            max-width: calc(100% / 2 - 10px - 20px);
          }
        } */
      }

      button.delete {
        height: 40px;
        width: 40px;
        border-radius: 5px;
        border: 0;
        background: #ff1329;
        color: #fff;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-bottom: 20px;
      }

      button.add-day {
        display: flex;
        float: right;
        width: 50px;
        height: 50px;
        border-radius: 10px;
        border: 1px solid #ddd;
        background: #181818;
        font-size: 35px;
        align-items: center;
        justify-content: center;
        line-height: 0;
        padding-bottom: 7px;
        border-radius: 50%;
        color: #fff;

        @media (max-width: 769px) {
          float: none;
          margin: 20px auto 0;
        }
      }
    }
  }

  .alert-box {
    background: #ddd;
    padding: 20px;
    border-radius: 5px;
    font-size: 16px;
    font-weight: 500;
    display: flex;
    justify-content: center;
    margin-bottom: 10px;
  }
`;

export const UserPic = styled.div`
  background-image: url('/static/media/user-picture.png');
  width: 182px;
  height: 166px;
  background-repeat: no-repeat;
  position: relative;
  margin: 0 auto;
  margin-bottom: 15px;

  @media(max-width: 769px) {
    margin: 0 auto;
  }

  label {
    background-color: #999999;
    position: absolute;
    top: 53px;
    right: 33px;
    width: 113px;
    height: 113px;
    border-radius: 50%;
    overflow: inherit;
    cursor: pointer;

    > div {
      visibility: hidden;
    }

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: center;
      border-radius: 50%;
    }

    input {
      visibility: hidden;
    }


    img.cam {
      position: absolute;
      right: -42px;
      bottom: -34px;
      cursor: pointer;
      object-fit: none;
    }
  }

  img.yellow {
    position: absolute;
    right: 2px;
    top: 46px;
  }

`;
