import React, { useCallback, useRef, useState } from 'react';
import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import * as Yup from 'yup';

import { useHistory } from 'react-router-dom';
import { useAuth } from '../../hooks/Auth';
import { useToast } from '../../hooks/Toast';
import getValidationErrors from '../../utils/getValidationErrors';

import Logo from '../../assets/logo.png';

import Input from '../../components/Input';
import Button from '../../components/Button';

import { Container } from './styles';

interface SignInCodeFormData {
  code: string;
  uuid: string;
}

const LoginCode: React.FC = () => {
  const [loading, setLoading, ] = useState(false);
  const formRef = useRef<FormHandles>(null);

  const { signInCode } = useAuth();
  const { addToast } = useToast();

  const handleSubmit = useCallback(
    async (data: SignInCodeFormData) => {
      setLoading(true);
      try {
        formRef.current?.setErrors({});
        const schema = Yup.object().shape({
          code: Yup.string()
            .required('Código obrigatório'),
        });

        await schema.validate(data, {
          abortEarly: false,
        });

        const uuid = localStorage.getItem('@ShieldCompliance:uuid');

        await signInCode({
          code: data.code,
          uuid: uuid,
        });
        setLoading(false);
      } catch (err) {
        setLoading(false);
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);

          formRef.current?.setErrors(errors);
        } else {
          addToast({
            type: 'error',
            title: 'Erro na autenticação',
            description:
              'Ocorreu um erro ao fazer login, cheque as credenciais',
          });
        }
      }
    },
    [addToast, signInCode],
  );

  return (
    <Container>
      <div className="box-login">
        <div className="logo-box">
          <img src={Logo} alt="Logo Shield Compliance" />
        </div>
        <div className="form-box">
          <Form onSubmit={handleSubmit} ref={formRef}>
            <label>Código de acesso recebido no seu e-mail</label>
            <Input
              name="code"
              fullWidth
            />
            <div className="buttons">
              <Button type="submit">
                {loading ? 'Acessando...' : 'Acessar'}
              </Button>
            </div>
          </Form>
        </div>
      </div>
    </Container>
  );
};

export default LoginCode;
