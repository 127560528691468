import styled, { css } from 'styled-components';

import Tooltip from '../Tooltip';

interface ContainerProps {
  isFocused: boolean;
  isErrored: boolean;
}

interface LabelProps {
  fullWidth: boolean;
}

export const Label = styled.div<LabelProps>`
  width: 100%;
  max-width: calc(50% - 15px);

  strong {
    font-weight: 500;
    margin-bottom: 2px;
    display: inline-block;
    font-size: 14px;
  }

  ${props =>
    props.fullWidth &&
    css`
      max-width: 100%;
    `}

  @media (max-width: 769px) {
    max-width: 100%;
  }
`;

export const Container = styled.div<ContainerProps>`
  height: 40px;
  background: #ffffff;
  border: 1px solid #c9c9c9;
  border-radius: 5px;
  margin-bottom: 20px;
  display: flex;
  align-items: center;
  padding: 0 10px;

  ${props =>
    props.isErrored &&
    css`
      border-color: #c53030;
    `}

  ${props =>
    props.isFocused &&
    css`
      border-color: #181818;
    `}

  input {
    background: transparent;
    flex: 1;
    border: 0;
    width: inherit;
    max-width: 100%;

    &::placeholder {
      color: #c9c9c9;
    }

    &:disabled {
      height: 100%;
      width: calc(100% + 20px);
      min-width: calc(100% + 20px);
      margin-left: -10px;
      background: #eee;
      padding: 10px;
      border-radius: 5px;
    }
  }

  button {
    border: 0;
    background: transparent;
    width: auto;
    color: #c3c3c3;
    font-size: 20px;
    line-height: 14px;
  }
`;

export const Error = styled(Tooltip)`
  height: 20px;
  margin-left: 16px;

  svg {
    margin: 0;
  }

  span {
    background: #c53030;
    color: #fff;

    &::before {
      border-color: #c53030 transparent;
    }
  }
`;
