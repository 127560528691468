import React, { useEffect, useState, useCallback } from 'react';
import { BiEdit } from 'react-icons/bi';
import { useHistory, useParams } from 'react-router-dom';

import Table from '../../components/Table';
import Pagination from '../../components/Pagination';
import Main from '../../components/Main';

import api from '../../services/api';

import { Container } from './styles';
import moment from 'moment';
import { Schedule } from '../../interfaces/Schedule';

const CustomerSchedules: React.FC = () => {
  const [items, setItems] = useState<Schedule[]>([]);
  const [filteredAttr, setFilteredAttr] = useState('');
  const [loadingData, setLoadingData] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);

  const history = useHistory();
  const { user_id } = useParams<any>();

  useEffect(() => {
    setLoadingData(true);
    api
      .get('schedules', { params: { search: filteredAttr, page: currentPage, user_id } })
      .then(response => {
        setItems(response.data.items);
        setLoadingData(false);
        setTotalPages(response.data.last_page);
      });
  }, [filteredAttr, currentPage, user_id]);

  let timeout: any;
  const handleSearchInput = useCallback((value) => {
    clearTimeout(timeout);
    timeout = setTimeout(() => {
      setFilteredAttr(value);
    }, 1000);
  }, [timeout]);

  return (
    <Main>
      <Container>
        <div className="breadcrumb">
          <h2>Início &gt; Agendamentos</h2>
        </div>

        <Table>
          <div className="filters to-right">
            <input
              type="text"
              name="search"
              placeholder="Filtrar por candidato"
              onChange={event => handleSearchInput(event.target.value)}
              className="filter-input"
            />
          </div>
          <div className="table-container">
            <table>
              <thead>
                <tr>
                  <td>Candidato</td>
                  <td>Status</td>
                  <td>Data do agendamento</td>
                  <td>Hora do agendamento</td>
                </tr>
              </thead>
              <tbody>
                {loadingData ? (
                  <tr>
                    <td colSpan={6}>
                      <p>Carregando agendamentos...</p>
                    </td>
                  </tr>
                ) : (
                    <>
                      {items.length > 0 ? (
                        <>
                          {items.map(item => (
                            <tr key={item.id!.toString()} className={item.status}>
                              <td>{item.customer_name}</td>
                              <td>{item.status}</td>
                              <td>{moment(item.date).format("DD/MM/YYYY")}</td>
                              <td>{item.hour}</td>
                            </tr>
                          ))}
                        </>
                      ) : (
                          <tr>
                            <td colSpan={6}>
                              Nenhum resultado encontrado
                            </td>
                          </tr>
                        )}
                    </>
                  )}
              </tbody>
            </table>
            <Pagination
              pages={totalPages}
              currentPage={currentPage}
              setPage={p => setCurrentPage(p)}
            />
          </div>

        </Table>
      </Container>
    </Main>
  );
};

export default CustomerSchedules;
