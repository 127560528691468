import React from 'react';

import Menu from '../Menu';
import Top from '../Top';

import { Container } from './styles';

const Main: React.FC = ({ children }) => {
  return (
    <Container>
      <Menu />

      <div className="content">
        <Top />
        {children}
      </div>
    </Container>
  );
};

export default Main;
