import React, { useEffect, useState, useCallback } from 'react';
import { BiEdit, BiTrash } from 'react-icons/bi';
import { useHistory } from 'react-router-dom';

import Table from '../../components/Table';
import Pagination from '../../components/Pagination';
import Main from '../../components/Main';
import Select from '../../components/Select';
import Input from '../../components/Input';

import api from '../../services/api';

import { Container } from './styles';
import moment from 'moment';
import { Project } from '../../interfaces/Project';
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import { useToast } from '../../hooks/Toast';
import { Form } from '@unform/web';

const Projects: React.FC = () => {
  const [items, setItems] = useState<Project[]>([]);
  const [filteredAttr, setFilteredAttr] = useState('');
  const [loadingData, setLoadingData] = useState(true);
  const [refresh, setRefresh] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [status, setStatus] = useState<string>('');

  const history = useHistory();
  const { addToast } = useToast();

  useEffect(() => {
    setLoadingData(true);
    api
      .get('projects', {
        params: {
          status: status ? status : '',
          search: filteredAttr,
          page: currentPage
        }
      })
      .then(response => {
        setItems(response.data.items);
        setLoadingData(false);
        setTotalPages(response.data.last_page);
      });
  }, [filteredAttr, currentPage, refresh, status]);

  const handleEdit = useCallback((item: Project) => {
    history.push(`${process.env.PUBLIC_URL}/projetos/${item.id}`);
  }, []);

  let timeout: any;
  const handleSearchInput = useCallback((value) => {
    clearTimeout(timeout);
    timeout = setTimeout(() => {
      setFilteredAttr(value);
    }, 1000);
  }, [timeout]);

  const handleDelete = useCallback(async (item) => {
    try {
      confirmAlert({
        customUI: ({ onClose }) => {
          return (
            <div className="custom-ui">
              <h1>Confirmar ação?</h1>
              <p>Gostaria de deletar este projeto? (Ação irreversível)</p>
              <button onClick={onClose}>Não</button>
              <button
                onClick={async () => {
                  await api.delete(
                    `projects/${item.id}`
                  );

                  addToast({
                    type: 'success',
                    title: 'Projeto deletado com sucesso!',
                  });
                  setRefresh(!refresh);

                  onClose();
                }}
              >
                Sim, deletar!
                </button>
            </div>
          );
        },
      });
    } catch (err) {
      addToast({
        type: 'error',
        title: 'Falha na exclusão do projeto!',
        description: 'Tente novamente ou contate o suporte.',
        timer: 6000
      });
    }
  }, [history, refresh]);

  return (
    <Main>
      <Container>
        <div className="breadcrumb">
          <h2>Início &gt; Projetos</h2>
        </div>

        <Table>
          <Form onSubmit={console.log} className="filters to-right">
            <Select
              name="status"
              label="Filtre por status"
              placeholder="Escolha..."
              isClearable
              isSearchable={false}
              isMulti={false}
              options={[
                { value: "Em andamento", label: "Em andamento" },
                { value: "Encerrado", label: "Encerrado" },
              ]}
              onChange={(obj: any) => {
                setStatus(obj?.value);
              }}
            />
            <Input
              name="search"
              label="Filtre por Cliente / Organização..."
              placeholder="Digite..."
              onChange={event => handleSearchInput(event.target.value)}
            />

            <button
              type="button"
              className="new-btn"
              onClick={() => history.push(`${process.env.PUBLIC_URL}/projetos/novo`)}
            >
              Novo
              </button>
          </Form>
          <div className="table-container">
            <table>
              <thead>
                <tr>
                  <td>Nome do projeto</td>
                  <td>Status do projeto</td>
                  <td>Link para acompanhamento</td>
                  <td>Relatório</td>
                  <td>Data de disponibilização</td>
                  <td>Editar</td>
                </tr>
              </thead>
              <tbody>
                {loadingData ? (
                  <tr>
                    <td colSpan={6}>
                      <p>Carregando projetos...</p>
                    </td>
                  </tr>
                ) : (
                  <>
                    {items.length > 0 ? (
                      <>
                        {items.map(item => (
                          <tr key={item.id!.toString()}>
                            <td>{item.name}</td>
                            <td>{item.status}</td>
                            <td><a href={`${item.project_link}`} target="_blank">{item.project_link}</a></td>
                            <td>{item.attached_document_availability ? "Disponível" : "Indisponível"}</td>
                            <td>{item.availability_date ? moment(item.availability_date).format("DD/MM/YYYY") : "-"}</td>
                            <td>
                              <button
                                type="button"
                                title="Excluir"
                                onClick={() => handleDelete(item)}
                              >
                                <BiTrash />
                              </button>
                              <button
                                type="button"
                                title="Editar"
                                onClick={() => handleEdit(item)}
                              >
                                <BiEdit />
                              </button>
                            </td>
                          </tr>
                        ))}
                      </>
                    ) : (
                      <tr>
                        <td colSpan={6}>
                          Nenhum resultado encontrado
                            </td>
                      </tr>
                    )}
                  </>
                )}
              </tbody>
            </table>
            <Pagination
              pages={totalPages}
              currentPage={currentPage}
              setPage={p => setCurrentPage(p)}
            />
          </div>

        </Table>
      </Container>
    </Main>
  );
};

export default Projects;
