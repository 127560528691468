import React, { useState, useCallback, useRef, useEffect, ChangeEvent } from 'react';
import { Form } from '@unform/web';
import { FormHandles } from '@unform/core';
import * as Yup from 'yup';
import getValidationErrors from '../../utils/getValidationErrors';
import 'react-confirm-alert/src/react-confirm-alert.css';
import { useParams, useHistory } from 'react-router-dom';

import { useToast } from '../../hooks/Toast';

import Input from '../../components/Input';
import Button from '../../components/Button';

import { FormContainer, UserPic } from './styles';
import api from '../../services/api';
import { FiXCircle, FiDelete } from 'react-icons/fi';

import Main from '../../components/Main';
import { Organization } from '../../interfaces/Organization';
import CustomSelect from '../../components/CustomSelect';
import { User } from '../../interfaces/User';
import { v4 as uuid } from 'uuid';
import Resizer from 'react-image-file-resizer';

import DefaultPic from "../../assets/default-image.png";
import CamPic from "../../assets/cam.png";
import InputFile from '../../components/InputFile';

interface CustomSelectI {
  value: string;
  label: string;
}

const FormModal: React.FC = () => {
  const formRef = useRef<FormHandles>(null);

  const [item, setItem] = useState<Organization | null>(null);
  const [customers, setCustomers] = useState<CustomSelectI[]>([]);
  const [preview, setPreview] = useState<any>();

  const history = useHistory();
  const { id } = useParams<any>();

  const { addToast } = useToast();

  useEffect(() => {
    api.get('all-customers').then(res => {
      const aux = res.data.items.map((item: User) => {
        return { value: item.id, label: item.name }
      });

      setCustomers([...aux]);
    }).catch(err => {
      addToast({
        type: 'error',
        title: 'Falha na requisição',
        description:
          'Ocorreu um erro ao carregar a lista de clientes, verifique sua conexão e tente novamente.',
      });
    });
  }, []);

  useEffect(() => {
    if (id && id !== 'novo') {
      api
        .get(`organizations/${id}`)
        .then(response => {
          setItem(response.data.item);
          const data = response.data.item;

          if (data.photo && !!data.photo) {
            setPreview(`https://shieldcompliance.nyc3.digitaloceanspaces.com/organizations/logos/${data.photo}`);
          }

          const users = response.data.item.users.map((user: User) => {
            return {
              value: user.id,
              label: user.name
            }
          });

          formRef?.current?.setData({
            ...response.data.item,
            users
          });

        })
        .catch(err => {
          console.log(err);
          addToast({
            type: 'error',
            title: 'Falha na requisição',
            description:
              'Ocorreu um erro ao carregar os dados, verifique sua conexão e tente novamente.',
          });
        });
    }
  }, [id]);

  const handleSubmit = useCallback(
    async (data: Organization) => {
      let formData;

      if (data.photo && !!data.photo) {
        const filename = item?.photo ? item?.photo : `logo_${uuid()}`;
        formData = new FormData();
        formData.append('file', data.photo, filename);
        data.photo = filename;
      }

      try {
        formRef.current?.setErrors({});

        const schema = Yup.object().shape({
          name: Yup.string().required('Nome obrigatório'),
          photo: Yup.string().nullable(),
          users: Yup.array().nullable(),
        });

        await schema.validate(data, {
          abortEarly: false,
        });

        let response;
        if (item) {
          response = await api.put(`organizations/${item.id}`, data);

          if (data.photo && !!data.photo) {
            await api.post(`/organizations/file`, formData).catch((err) => console.log(err));
          }
        } else {
          response = await api.post('organizations', data);

          if (data.photo && !!data.photo) {
            await api.post(`/organizations/file`, formData).catch((err) => console.log(err));
          }
        }

        addToast({
          type: 'success',
          title: `Cadastro ${item ? 'atualizado' : 'realizado'}`,
          description: response.data.message,
        });

        history.go(-1);
      } catch (err) {
        if (err instanceof Yup.ValidationError) {
          console.log(err);
          const errors = getValidationErrors(err);

          formRef.current?.setErrors(errors);

          return;
        } else {
          addToast({
            type: 'error',
            title: 'Falha na requisição',
            description:
              'Ocorreu um erro ao salvar, verifique os dados e tente novamente',
          });
        }
      }
    },
    [history, item],
  );

  const handlePicture = useCallback(async (e: ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];
    if (!file) setPreview(null);

    const compressedFile: any = await resizeFile(file); // Comprimindo arquivo
    const previewURL = URL.createObjectURL(compressedFile); // Setando a preview
    setPreview(previewURL);
  }, []);

  // Função para compressão de imagens
  const resizeFile = (file: any) =>
    new Promise(resolve => {
      Resizer.imageFileResizer(
        file,
        300,
        300,
        'JPEG',
        40,
        0,
        (uri: any) => {
          resolve(uri);
        },
        'blob',
      );
    });

  return (
    <Main>
      <FormContainer>
        <div className="breadcrumb">
          <h2>Início / {item ? 'Editar' : 'Nova'} organização</h2>
        </div>
        <Form
          onSubmit={handleSubmit}
          ref={formRef}
        >
          <button type="button" onClick={() => history.go(-1)} className="close-btn">
            <FiXCircle size={30} />
          </button>
          <UserPic>
            <label htmlFor="picture">
              {preview ? (
                <img src={preview} alt="Preview" width="100" className="preview" />
              ) : (
                  <img src={DefaultPic} width="100" className="preview" />
                )
              }
              <InputFile type="file" name="photo" id="picture" onChange={handlePicture} accept="image/*" />
              <img src={CamPic} className="cam" alt="Cam" />
            </label>
          </UserPic>
          <Input name="name" label="Nome" fullWidth />
          <CustomSelect name="users" options={customers} label="Vincule clientes" placeholder="Selecionar..." noOptionsMessage={() => "Sem resultados..."} fullWidth />
          <div className="buttons">
            <Button type="submit">Salvar</Button>
          </div>
        </Form>
      </FormContainer>
    </Main>
  );
};

export default FormModal;
