import React, { useEffect, useState, useCallback, useMemo } from 'react';
import DayPicker, { DayModifiers } from 'react-day-picker';
import 'react-day-picker/lib/style.css';
import { FiClock } from 'react-icons/fi';
import moment from 'moment';

import { Form as FilterForm } from '@unform/web';
import { useHistory } from 'react-router-dom';

import Main from '../../components/Main';
import Table from '../../components/Table';
import Select from '../../components/Select';

import { useAuth } from '../../hooks/Auth';

import api from '../../services/api';

import {
  Container,
  Schedules,
  BoxCalendar,
  Appointment,
  Content,
} from './styles';
import { Schedule } from '../../interfaces/Schedule';
import Input from '../../components/Input';

interface Filter {
  offices: number[];
  modalities: string;
  search: string;
  status: string;
}

const Calendar: React.FC = () => {
  const [selectedDate, setSelectedDate] = useState(new Date());

  const [currentMonth, setCurrentMonth] = useState(new Date());
  const [monthAvailability, setMonthAvailability] = useState<string[]>([]);
  const [schedules, setSchedules] = useState<Schedule[]>([]);
  const [filters, setFilters] = useState<Filter>({
    offices: [],
    modalities: '',
    search: '',
    status: '',
  });
  const [loadingData, setLoadingData] = useState(true);

  const { user } = useAuth();
  const history = useHistory();


  // load schedules
  useEffect(() => {

    setLoadingData(true);
    api
      .get('schedules', {
        params: {
          date: moment(selectedDate).format('YYYY-MM-DD'),
          status: filters.status,
          search: filters.search,
          modality: filters.modalities ? filters.modalities : undefined,
          offices: JSON.stringify(filters.offices),
        }
      })
      .then(response => {
        const schedulesData = response.data.items.map((item: Schedule) => {
          return {
            ...item,
            // date: new Date(item.date),
            // customer_date_of_birth: item.customer_date_of_birth
            //   ? new Date(item.customer_date_of_birth)
            //   : undefined,
          };
        });
        setSchedules(schedulesData);
        setLoadingData(false);
      });
  }, [selectedDate, filters]);
  // -- -- //

  // -- Load available days on month -- //
  useEffect(() => {
    const offF = filters.offices.length > 0 ? JSON.stringify(filters.offices) : undefined;
    api
      .get('scheduled-days', {
        params: {
          offices: offF,
          modality: filters.modalities ? filters.modalities : undefined,
          search: filters.search ? filters.search : undefined,
          status: filters.status ? filters.status : undefined,
          month: currentMonth.getMonth() + 1,
          year: currentMonth.getFullYear(),
        },
      })
      .then(response => {
        setMonthAvailability([...response.data.array_days]);
      });
  }, [currentMonth, filters]);

  const handleDateChange = useCallback((day: Date, modifiers: DayModifiers) => {
    if (modifiers.available && !modifiers.disabled) {
      setSelectedDate(day);
    }
  }, []);

  const handleMonthChange = useCallback((month: Date) => {
    setCurrentMonth(month);
  }, []);

  const daysInMonth = useCallback((month: number, year: number) => {
    return new Date(year, month, 0).getDate();
  }, []);

  const handleFilterStatus = useCallback(
    value => {
      if (value) {
        setFilters({ ...filters, status: value.value });
      } else {
        setFilters({ ...filters, status: '' });
      }
    },
    [filters],
  );

  const disabledDays = useMemo(() => {
    const countDays = daysInMonth(
      currentMonth.getMonth() + 1,
      currentMonth.getFullYear(),
    );
    const dates: Date[] = [];
    const year = currentMonth.getFullYear();
    const month = currentMonth.getMonth();

    for (let i = 1; i <= countDays; i++) {
      let x = i.toString();
      x = i < 10 ? `0${i}` : `${i}`;
      const idx = monthAvailability.indexOf(x);
      if (idx === -1) {
        dates.push(new Date(year, month, i));
      }
    }
    return dates;
  }, [currentMonth, monthAvailability]);

  let timeout: any;
  const handleSearchInput = useCallback((value) => {
    clearTimeout(timeout);
    timeout = setTimeout(() => {
      setFilters({ ...filters, search: value });
    }, 1000);
  }, [timeout]);

  return (
    <Main>
      <Container>
        <div className="breadcrumb">
          <h2>Início &gt; Agenda</h2>
        </div>

        <Table>
          <strong>Filtros</strong>
          <br />
          <div className="filters">
            <FilterForm onSubmit={() => console.log('A')}>
              <Select
                name="status"
                options={[
                  { value: 'Confirmado', label: 'Confirmado' },
                  { value: 'Cancelado', label: 'Cancelado' },
                  { value: 'Pendente', label: 'Pendente' },
                ]}
                onChange={handleFilterStatus}
                isClearable
                placeholder="Status"
              />
              <Input
                type="text"
                name="search"
                placeholder="Filtrar por candidato"
                onChange={event =>
                  handleSearchInput(event.target.value)
                }
              />
            </FilterForm>
            <div>
              <button
                type="button"
                className="new-btn"
                onClick={() => history.push(`${process.env.PUBLIC_URL}/agenda/novo`)}
              >
                Novo
              </button>
            </div>
          </div>

          <Content>
            <Schedules>
              {loadingData ? (
                <div className="loading">
                  <p className="alert">Carregando agendamentos...</p>
                </div>
              ) : (
                  <>
                    {schedules.length > 0 ? (
                      schedules.map(item => (
                        <Appointment
                          key={item.id.toString()}
                          onClick={() => history.push(`${process.env.PUBLIC_URL}/agenda/${item.id}`)}
                          className={`${item.status}`}
                        >
                          <span>
                            <FiClock size={20} />
                            {item.hour}
                          </span>
                          <div>
                            <strong>Nome candidato</strong>
                            {item.customer_name}
                          </div>
                          <div>
                            <strong>E-mail candidato</strong>
                            {item.customer_name}
                          </div>
                          <div>
                            <strong>Tel. candidato</strong>
                            {item.customer_phone}
                          </div>
                        </Appointment>
                      ))
                    ) : (
                        <p className="alert">
                          Nenhum agendamento registrado para o dia atual,
                          selecione um dia disponível no calendário ao lado.
                        </p>
                      )}
                  </>
                )}
            </Schedules>
            <BoxCalendar>
              <DayPicker
                weekdaysShort={['D', 'S', 'T', 'Q', 'Q', 'S', 'S']}
                // fromMonth={new Date()}
                disabledDays={[...disabledDays]}
                modifiers={{
                  available: { daysOfWeek: [0, 1, 2, 3, 4, 5, 6] },
                }}
                selectedDays={selectedDate}
                onDayClick={handleDateChange}
                onMonthChange={handleMonthChange}
                months={[
                  'Janeiro',
                  'Fevereiro',
                  'Março',
                  'Abril',
                  'Maio',
                  'Junho',
                  'Julho',
                  'Agosto',
                  'Setembro',
                  'Outubro',
                  'Novembro',
                  'Dezembro',
                ]}
              />
            </BoxCalendar>
          </Content>
        </Table>
      </Container>
    </Main >
  );
};

export default Calendar;
