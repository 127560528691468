import styled from 'styled-components';
import { shade } from 'polished';

export const Main = styled.div`
  padding: 0;
  place-items: center;
  flex-direction: column;
`;

export const Container = styled.div`
  padding: 0 50px;
  flex-direction: column;

  @media (max-width: 769px) {
    padding: 0 15px;
  }
`;

export const NavBar = styled.div`
  padding: 20px 50px;
  background-image: url('../../bg_navbar.png');
  background-position: center;
  background-size: cover;
  width: 100%;
  height: 120px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  a {
    text-decoration: none;
    display: flex;
    align-items: center;
    color: #fff;
    font-weight: 600;

    svg {
      margin-right: 15px;
    }
  }

  @media (max-width: 769px) {
    padding: 0 15px;

    img {
      width: 86px;
    }

    a {
      text-decoration: none;
      display: flex;
      align-items: center;
      color: #fff;
      font-weight: 600;
      font-size: 13px;

      svg {
        margin-right: 5px;
      }
    }
  }
`;

export const Content = styled.div`
  padding: 40px 0;
  background: #181818;
  min-height: calc(100vh - 120px);

  h2 {
    color: #fff;
    text-align: center;
    margin-bottom: 30px;
  }

  p {
    color: #fff;
    text-align: left;
    font-size: 16px;
    font-weight: 100;

    &:first-of-type {
      display: flex;
      align-items: center;

      svg {
        margin-right: 15px;
      }
    }
  }

  .hour-list {
    max-height: 55vh;
    overflow-y: auto;
    padding: 0 15px;

    .alert {
      display: flex;
      justify-content: center;
      margin-bottom: 20px;
      width: 100%;
    }

    .no-hour-item {
      width: 100%;
      background: #353535;
      height: 50px;
      margin-bottom: 20px;
      border-radius: 5px;
    }

    .hour-item {
      width: 100%;
      height: 50px;
      background: transparent;
      border: 2px solid #fff;
      border-radius: 5px;
      margin-bottom: 15px;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      transition: 0.3s;

      p {
        font-weight: 600;
        color: #fff;
      }

      &:hover {
        background: #fff;
        color: #181818;

        p {
          color: #181818;
        }
      }

      &.active {
        background: #fff;
        color: #181818;

        p {
          color: #181818;
        }
      }
    }

    &::-webkit-scrollbar {
      width: 12px;
    }

    /* Track */
    &::-webkit-scrollbar-track {
      background: #f1f1f1;
      border-radius: 3px;
    }

    /* Handle */
    &::-webkit-scrollbar-thumb {
      background: #000;
      border-radius: 3px;
    }

    /* Handle on hover */
    &::-webkit-scrollbar-thumb:hover {
      background: #555;
    }

    @media (max-width: 768px) {
      padding: 0;
      overflow: hidden;
      height: auto;
      max-height: 100%;
    }
  }

  .buttons {
    margin-top: 20px;

    .confirm-button {
      background: #fff;
      border: 2px solid #fff;
      padding: 15px;
      width: 200px;
      height: 50px;
      border-radius: 5px;
      color: #181818;
      font-weight: 600;
      transition: 0.3s;

      &:hover {
        background: transparent;
        color: #fff;
      }
    }

    .back-button {
      background: transparent;
      border: 1px solid #fff;
      padding: 15px;
      width: 200px;
      height: 50px;
      border-radius: 5px;
      color: #fff;
      font-weight: 600;
      transition: 0.3s;
      margin-right: 30px;

      &:hover {
        background: #fff;
        color: #181818;
      }
    }
  }

  @media (max-width: 768px) {
    padding: 0;
    padding-bottom: 40px;

    h2 {
      margin: 0;
      margin-top: 40px;
      margin-bottom: 20px;
      text-align: center;
    }

    p {
      text-align: center;
    }

    .buttons {
      .confirm-button {
        width: 100%;
      }

      button {
        width: 100% !important;
        margin: 0 !important;
        margin-bottom: 10px !important;
      }
    }
  }
`;

export const Footer = styled.div`
  padding: 0 50px;
  place-items: center;
  flex-direction: column;

  @media (max-width: 769px) {
    padding: 0 15px;
  }
`;

export const Row = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;

  form {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
  }

  &.row-buttons {
    justify-content: space-between;
    border-top: 1px solid #707070;
    margin-top: 20px;
    padding-top: 30px;

    > div {
      display: flex;

      @media(max-width: 769px) {
        width: 100% !important;
      }
    }

    button {
      width: 250px;

      @media(max-width: 769px) {
        width: 100% !important;
        margin: 0 !important;
      }

      &.next {
        background: #fff;
        border: 2px solid #181818;
        color: #181818;

        @media(max-width: 769px) {
          display: none;
        }
      }

      &.prev {
        background: #fff;
        border: 2px solid #a5a5a5;
        color: #a5a5a5;

        @media(max-width: 769px) {
          display: none;
        }
      }

      & + button {
        margin-left: 20px;
      }
    }
  }
`;

export const Col = styled.div`
  width: 30%;
  text-align: center;

  p {
    a {
      color: #fff;
      text-decoration: none;
    }
  }

  &:first-of-type {
    h2 {
      text-align: left !important;
    }
  }

  @media (max-width: 769px) {
    width: 100% !important;

    &:first-of-type {
      h2 {
        text-align: center !important;
      }
    }
  }

  > div {
    width: 100% !important;
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    justify-content: space-between;
  }

  &.step2 {
    width: 48%;

    form {
      .label {
        color: #fff;
        text-align: left;
      }

      > div {
        margin: 0;

        > div {
          background: transparent;
          margin-bottom: 8px;
          border-color: #fff !important;

          input {
            color: #fff !important;

            &::placeholder {
              color: #d6d6d6;
              font-size: 13px;
            }
          }

          textarea {
            color: #fff !important;

            &::placeholder {
              color: #d6d6d6;
              font-size: 13px;
            }
          }
        }
      }

      .buttons {
        margin-top: 20px;
        button {
          border: 1px solid #fff;
        }
      }

      .remove-attachment {
        cursor: pointer;
        display: flex;
        margin-bottom: 10px !important;
        align-items: center;
        justify-content: flex-end;
        width: 100%;

        svg {
          color: #fff;
          margin-left: 10px;
        }
      }
    }
  }
`;

export const BoxCalendar = styled.div`
  width: 380px;
  max-width: 100%;

  @media (max-width: 1230px) {
    margin-left: 0;
    margin-bottom: 30px;
  }

  .DayPicker {
    background: #181818;
    border-radius: 10px;
  }

  .DayPicker-wrapper {
    padding-bottom: 0;
  }

  .DayPicker,
  .DayPicker-Month {
    width: 100%;
  }

  .DayPicker-Month {
    border-collapse: separate;
    border-spacing: 8px;
    margin: 16px;
    width: calc(100% - 32px);

    @media (max-width: 768px) {
      margin-bottom: -30px;
    }
  }

  .DayPicker-Day {
    width: 40px;
    height: 40px;
  }

  .DayPicker-Day--available:not(.DayPicker-Day--outside) {
    background: #2b2b2b;
    border-radius: 10px;
    color: #fff;
  }

  .DayPicker:not(.DayPicker--interactionDisabled)
    .DayPicker-Day:not(.DayPicker-Day--disabled):not(.DayPicker-Day--selected):not(.DayPicker-Day--outside):hover {
    background: ${shade(0.2, '#2b2b2b')};
  }

  .DayPicker-Day--today {
    font-weight: normal;
  }

  .DayPicker-Day--disabled {
    color: #b4b4b4 !important;
    background: transparent !important;
  }

  .DayPicker-Day--selected {
    background: #fff !important;
    border-radius: 10px;
    color: #232129 !important;
  }

  .DayPicker-Weekday {
    color: #fff;
  }

  .DayPicker-Caption {
    color: #fff;
    background: #000;
    padding: 10px;
    border-radius: 5px;
  }

  .DayPicker-NavButton {
    filter: brightness(0) invert(1);
    margin-top: 13px;
  }

  .DayPicker-Caption select {
    border-radius: 5px;
    padding: 5px;
    background: #fff;
    margin-right: 7px;
    border: 0;
  }
`;
