import { Form } from '@unform/web';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { Chart } from 'react-google-charts';
import { useHistory } from 'react-router-dom';
import Input from '../../components/Input';

import Main from '../../components/Main'
  ;
import { useAuth } from '../../hooks/Auth';
import api from '../../services/api';

import { Container, TopBlocks, PieChart, BarChart, LineChart, Filters } from './styles';

interface selectType {
  value: number;
  label: string;
}

const CustomerDashboard: React.FC = () => {
  const [pendingSchedules, setPendingSchedules] = useState();
  const [confirmedSchedules, setConfirmedSchedules] = useState();
  const [canceledSchedules, setCanceledSchedules] = useState();
  const [doneSchedules, setDoneSchedules] = useState();
  const [pieData, setPieData] = useState([]);
  const [barData, setBarData] = useState([]);
  const [lineData, setLineData] = useState([]);
  const [loadingCharts, setLoadingCharts] = useState(true);
  const [canLoad, setCanLoad] = useState(true);
  const [dateFrom, setDateFrom] = useState<any>(null);
  const [dateUntil, setDateUntil] = useState<any>(null);
  const history = useHistory();
  const { user } = useAuth();

  // loadCharts
  useEffect(() => {
    if (canLoad) {
      setLoadingCharts(true);

      api
        .get(`/customer-numbers-data`, {
          params: {
            user_id: user.id,
            dateFrom,
            dateUntil,
          }
        })
        .then(response => {
          setPendingSchedules(response.data.pending_schedules.total);
          setConfirmedSchedules(response.data.confirmed_schedules.total);
          setCanceledSchedules(response.data.canceled_schedules.total);
          setDoneSchedules(response.data.done_schedules.total);
        });

      api
        .get(`customer-pie-chart-data`, {
          params: {
            user_id: user.id,
            dateFrom,
            dateUntil,
          }
        })
        .then(response => {
          setPieData(response.data.results);
        });

      api
        .get(`customer-column-chart-data`, {
          params: {
            user_id: user.id,
            dateFrom,
            dateUntil,
          }
        })
        .then(response => {
          setBarData(response.data.results);
          setTimeout(() => {
            setLoadingCharts(false);
          }, 500);
        });
    }
  }, [dateFrom, dateUntil]);

  return (
    <Main>
      <Container>
        <div className="breadcrumb">
          <div>
            <h2>Início</h2>
          </div>
        </div>

        <Filters>
          <Form onSubmit={console.log}>
            <Input type="date" name="date_from" label="Filtrar data desde" placeholder="__/__/____" fullWidth
              onChange={(e) => {
                if (!!e.currentTarget.value) {
                  setDateFrom(e.currentTarget.value);
                } else {
                  setDateFrom(null);
                }
              }}
              max={dateUntil}
            />
            <Input type="date" name="date_until" label="Filtrar data até" placeholder="__/__/____" fullWidth
              onChange={(e) => {
                if (!!e.currentTarget.value) {
                  setDateUntil(e.currentTarget.value);
                } else {
                  setDateUntil(null);
                }
              }}
              min={dateFrom}
            />
          </Form>
        </Filters>
        <TopBlocks>
          <div className="block" style={{ cursor: 'pointer' }} onClick={() => history.push(`/meus-agendamentos/${user.id}`)}>
            {loadingCharts ? (
              <div className="loading">
                <h2 />
                <h4 />
                <p />
              </div>
            ) : (
              <>
                <div>
                  <h2>{pendingSchedules}</h2>
                  <h4>Agendamentos pendentes</h4>
                </div>
              </>
            )}
          </div>

          <div className="block" style={{ cursor: 'pointer' }} onClick={() => history.push(`/meus-agendamentos/${user.id}`)}>
            {loadingCharts ? (
              <div className="loading">
                <h2 />
                <h4 />
                <p />
              </div>
            ) : (
              <>
                <div>
                  <h2>{confirmedSchedules}</h2>
                  <h4>Agendamentos confirmados</h4>
                </div>
              </>
            )}
          </div>

          <div className="block" style={{ cursor: 'pointer' }} onClick={() => history.push(`/meus-agendamentos/${user.id}`)}>
            {loadingCharts ? (
              <div className="loading">
                <h2 />
                <h4 />
                <p />
              </div>
            ) : (
              <>
                <div>
                  <h2>{doneSchedules}</h2>
                  <h4>Agendamentos concluídos</h4>
                </div>
              </>
            )}
          </div>

          <div className="block" style={{ cursor: 'pointer' }} onClick={() => history.push(`/meus-agendamentos/${user.id}`)}>
            {loadingCharts ? (
              <div className="loading">
                <h2 />
                <h4 />
                <p />
              </div>
            ) : (
              <>
                <div>
                  <h2>{canceledSchedules}</h2>
                  <h4>Agendamentos cancelados</h4>
                </div>
              </>
            )}
          </div>
        </TopBlocks>

        <div className="row">
          <PieChart>
            <h3>Projetos por tipo </h3>
            {loadingCharts ? (
              <div className="loading">
                <div className="chart" />
              </div>
            ) : (
              <>
                {pieData.length > 0 ? (
                  <div className="chart-box">
                    <Chart
                      width="100%"
                      height="300px"
                      chartType="PieChart"
                      data={pieData}
                      options={{
                        slices: {
                          0: { color: '#3f3f3f' },
                          1: { color: '#242323' },
                          2: { color: '#181818' },
                          3: { color: '#ddd' },
                        },
                        pieHole: 0.4,
                        legend: {
                          position: 'bottom',
                        },
                      }}
                      rootProps={{ 'data-testid': '1' }}
                    />
                  </div>
                ) : (
                  <p className="alert">Nenhum resultado encontrado</p>
                )}
              </>
            )}
          </PieChart>

          <BarChart>
            <h3>Agendamentos por mês</h3>
            {loadingCharts ? (
              <div className="loading">
                <div className="chart" />
              </div>
            ) : (
              <div className="chart-box">
                <Chart
                  width="100%"
                  height="300px"
                  chartType="Bar"
                  data={barData}
                  rootProps={{ 'data-testid': '2' }}
                  options={{
                    legend: { position: 'none' },
                    colors: ["#3f3f3f"],
                  }}
                />
              </div>
            )}
          </BarChart>
        </div>
      </Container>
    </Main >
  );
};

export default CustomerDashboard;
