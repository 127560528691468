import axios from 'axios';

const bcrypt = require('bcryptjs');

const api = axios.create({
  // baseURL: 'http://localhost:8080/',
  // baseURL: 'https://sistemashieldcompliance.com.br/api/',
  baseURL: 'https://api.sistemashieldcompliance.com.br/',
});

const hash = bcrypt.hashSync('fydJfN38c0rxfQk12MyIxczrPgD7ncPA4hT', 10);
api.defaults.headers.app_key = hash;

export default api;
