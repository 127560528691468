import React, { useEffect, useState, useCallback } from 'react';
import { FiEdit } from 'react-icons/fi';
import { useHistory } from 'react-router-dom';

import Table from '../../components/Table';
import Pagination from '../../components/Pagination';

import api from '../../services/api';

import Main from '../../components/Main';
import { Container } from './styles';
import { User } from '../../interfaces/User';
import { IoMdCheckmarkCircleOutline } from 'react-icons/io';
import { AiOutlineCloseCircle } from 'react-icons/ai';
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import { useToast } from '../../hooks/Toast';
import { BiTrash } from 'react-icons/bi';

const Users: React.FC = () => {
  const [users, setUsers] = useState<User[]>([]);
  const [filteredAttr, setFilteredAttr] = useState('');
  const [loadingData, setLoadingData] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [refresh, setRefresh] = useState(false);

  const history = useHistory();
  const { addToast } = useToast();

  useEffect(() => {
    setLoadingData(true);
    api
      .get('users', {
        params: {
          search: filteredAttr,
          page: currentPage,
        },
      })
      .then(response => {
        setUsers(response.data.items);
        setLoadingData(false);
        setTotalPages(response.data.last_page);
      });
  }, [filteredAttr, currentPage, refresh]);

  const handleEdit = useCallback(
    user => {
      history.push(`${process.env.PUBLIC_URL}/usuarios/${user.id}`);
    },
    [history],
  );

  let timeout: any;
  const handleSearchInput = useCallback((value) => {
    clearTimeout(timeout);
    timeout = setTimeout(() => {
      setFilteredAttr(value);
    }, 1000);
  }, [timeout]);

  const handleDelete = useCallback(async (item) => {
    try {
      confirmAlert({
        customUI: ({ onClose }) => {
          return (
            <div className="custom-ui">
              <h1>Confirmar ação?</h1>
              <p>Gostaria de deletar o usuário {item.name}? (Ação irreversível)</p>
              <button onClick={onClose}>Não</button>
              <button
                onClick={async () => {
                  await api.delete(
                    `users/${item.id}`
                  );

                  addToast({
                    type: 'success',
                    title: 'Usuário deletado com sucesso!',
                  });
                  setRefresh(!refresh);

                  onClose();
                }}
              >
                Sim, deletar!
                </button>
            </div>
          );
        },
      });
    } catch (err) {
      addToast({
        type: 'error',
        title: 'Falha na exclusão do usuário!',
        description: 'Tente novamente ou contate o suporte.',
        timer: 6000
      });
    }
  }, [history, refresh]);

  return (
    <Main>
      <Container>
        <div className="breadcrumb">
          <h2>Início &gt; Usuários</h2>
        </div>

        <Table>
          <div className="filters to-right">
            <input
              type="text"
              name="search"
              placeholder="Filtrar usuários"
              onChange={event => handleSearchInput(event.target.value)}
              className="filter-input"
            />
            <button
              type="button"
              className="new-btn"
              onClick={() =>
                history.push(`${process.env.PUBLIC_URL}/usuarios/novo`)
              }
            >
              Novo
            </button>
          </div>
          <div className="table-container">
            <table>
              <thead>
                <tr>
                  <td>Nome</td>
                  <td>E-mail</td>
                  <td>Telefone</td>
                  <td>Permissão</td>
                  <td>Realiza agendamento?</td>
                  <td>Status</td>
                  <td>Editar</td>
                </tr>
              </thead>
              <tbody>
                {loadingData ? (
                  <tr>
                    <td colSpan={7}>
                      Carregando usuários...
                    </td>
                  </tr>
                ) : (
                    <>
                      {
                        users.length > 0 ?
                          users.map(user => (
                            <tr key={user.id.toString()}>
                              <td>{user.name}</td>
                              <td>{user.email}</td>
                              <td>{user.phone ? user.phone : "Não informado"}</td>
                              <td>{user?.role?.name}</td>
                              <td className="icon">{user.schedule_pci ? <IoMdCheckmarkCircleOutline size={22} className="active" /> : <AiOutlineCloseCircle size={22} className="inactive" />}</td>
                              <td>{user.active ? "Ativo" : "Inativo"}</td>
                              <td>
                              <button
                                type="button"
                                title="Excluir"
                                onClick={() => handleDelete(user)}
                              >
                                <BiTrash />
                              </button>
                                <button
                                  type="button"
                                  onClick={() => handleEdit(user)}
                                >
                                  <FiEdit />
                                </button>
                              </td>
                            </tr>
                          )) : (
                            <tr>
                              <td colSpan={7}>
                                Nenhum resultado encontrado
                              </td>
                            </tr>
                          )
                      }
                    </>
                  )}
              </tbody>
            </table>
            <Pagination
              pages={totalPages}
              currentPage={currentPage}
              setPage={p => setCurrentPage(p)}
            />
          </div>
        </Table>
      </Container>
    </Main>
  );
};

export default Users;
