import React, { useState, useCallback, useRef, useEffect } from 'react';
import { Form } from '@unform/web';
import { FormHandles } from '@unform/core';
import * as Yup from 'yup';
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import getDay from 'date-fns/getDay';
import { FaWhatsapp } from 'react-icons/fa';
import { FiXCircle } from 'react-icons/fi';
import { useHistory, useParams } from 'react-router-dom';
import moment from 'moment';

import api from '../../services/api';

import { useToast } from '../../hooks/Toast';

import Main from '../../components/Main';
import Input from '../../components/Input';
import DatePicker from '../../components/DatePicker';
import Select from '../../components/Select';
import InputMask from '../../components/InputMask';
import Button from '../../components/Button';
import InputFile from '../../components/InputFile';

import getValidationErrors from '../../utils/getValidationErrors';

import { Col, FormContainer, Row } from './styles';

import { v4 as uuid } from 'uuid';
import { Schedule } from '../../interfaces/Schedule';
import Textarea from '../../components/Textarea';
import { RiDeleteBack2Fill } from 'react-icons/ri';
import { AiFillEye } from 'react-icons/ai';
import { HiTrash } from 'react-icons/hi';

import Dropzone from 'react-dropzone-uploader';
import 'react-dropzone-uploader/dist/styles.css'

interface SelectType {
  label: string;
  value: number | string;
}

const FormC: React.FC = () => {
  const formRef = useRef<FormHandles>(null);
  const [arrayOfDays] = useState([
    'DOMINGO',
    'SEGUNDA-FEIRA',
    'TERÇA-FEIRA',
    'QUARTA-FEIRA',
    'QUINTA-FEIRA',
    'SEXTA-FEIRA',
    'SÁBADO',
  ]);
  const { addToast } = useToast();

  const [appointment, setAppointment] = useState<Schedule | null>();
  const [phoneMask, setPhoneMask] = useState('(99) 9999-9999?');
  const [schedulingDate, setSchedulingDate] = useState<Date | null>();
  const [availableHours, setAvailableHours] = useState([]);
  const [availableDays, setAvailableDays] = useState<any[]>([]);
  const [defaultAvailableDays, setDefaultAvailableDays] = useState<any[]>([]);
  const [timeStart, setTimeStart] = useState<SelectType | null>();
  const [loadingSubmit, setLoadingSubmit] = useState(false);
  const [selectedUserId, setSelectedUserId] = useState(undefined);
  const [refresh, setRefresh] = useState<boolean>(false);
  const [currentMonth, setCurrentMonth] = useState(new Date());

  const [hasFileSelected, setHasFileSelected] = useState<boolean>(false);
  const [selectedFiles, setSelectedFiles] = useState<any[]>([]);
  const [allCustomers, setAllCustomers] = useState<any[]>([]);
  const [emailContentChange, setEmailContentChange] = useState<boolean>(false);

  const history = useHistory();
  const { appointment_id } = useParams<any>();

  // -- Load Appointment - //
  const setCurrentDatas = useCallback(async (appointmentAux) => {
    const dateAux = new Date(appointmentAux.date);
    dateAux.setUTCHours(23);
    await setSchedulingDate(dateAux);
  }, []);

  useEffect(() => {
    if (appointment_id && appointment_id !== 'novo') {
      api.get(`schedules/${appointment_id}`).then(async response => {
        const appointmentAux = {
          ...response.data.item,
          date: moment(response.data.item.date).format('YYYY-MM-DD'),
        };

        if (appointmentAux.customer_phone && appointmentAux.customer_phone?.length >= 15) {
          setPhoneMask('(99) 99999-9999');
        } else {
          setPhoneMask('(99) 9999-9999?');
        }

        setCurrentDatas(appointmentAux);
        setAppointment(appointmentAux);

        formRef?.current?.setData({
          ...appointmentAux,
          hour: { value: appointmentAux.hour, label: appointmentAux.hour },
          user_id: appointmentAux.user ? { value: appointmentAux.user.id, label: appointmentAux.user.name } : undefined,
        });
      });
    }
  }, [refresh]);

  useEffect(() => {
    api
      .get(`available-days`, {
        params: {
          year: moment(currentMonth).format('YYYY'),
          month: moment(currentMonth).format('MM'),
        }
      })
      .then(response => {
        const aux = response.data.success.items;

        const availableDaysAux = aux?.map((conf: any) => {
          return moment(conf.date).format('D');
        });

        setAvailableDays([...availableDaysAux]);
        setDefaultAvailableDays([...availableDaysAux]);
      })
      .catch(err => {
        console.log(err);
        addToast({
          type: 'error',
          title: 'Oops!',
          description:
            err.response?.data?.error?.error_message || "Ocorreu um erro, tente novamente.",
        });
      });
  }, [addToast, currentMonth, refresh]);

  useEffect(() => {
    api
      .get(`all-customers`)
      .then(response => {
        const aux = response.data.items;

        const customersAux = aux?.map((customer: any) => {
          return {
            value: customer.id,
            label: customer.name,
          };
        });

        console.log('USERS', customersAux)

        setAllCustomers([...customersAux]);
      })
      .catch(err => {
        addToast({
          type: 'error',
          title: 'Oops!',
          description:
            err.response?.data?.error?.error_message || "Ocorreu um erro, tente novamente.",
        });
      });
  }, [addToast, currentMonth, refresh]);

  const handleMonthChange = useCallback((month: Date) => {
    setCurrentMonth(month);
  }, []);

  useEffect(() => {
    if (schedulingDate) {
      loadAvailableHours(moment(schedulingDate).format("YYYY-MM-DD"));
    }
  }, [schedulingDate]);

  const isAvailableDays = useCallback(
    date => {
      const day = moment(date).format('D');

      return availableDays.includes(day);
    },
    [availableDays],
  );

  // -- Verify Available Hours -- //
  const loadAvailableHours = useCallback(
    async (date: string) => {
      try {
        const response = await api.get(`available-hours`, {
          params: {
            date: `${date}`,
          },
        });

        const auxArray: any = [];
        response.data.available_hours_array?.map((hour: any) => {
          const now = moment().format('YYYY-MM-DD HH:mm');
          const _hour = moment(schedulingDate).set({ hour: parseInt(hour.split(":")[0]), minute: parseInt(hour.split(":")[1]) });

          if (moment(_hour).isAfter(now)) {
            auxArray.push(hour);
          }
        });

        const options = auxArray.map(
          (item: any) => ({
            value: item,
            label: item,
          }),
        );

        setAvailableHours(options);

      } catch (error) {
        console.log(error);
      }
    },
    [arrayOfDays, schedulingDate, availableHours],
  );

  useEffect(() => {
    if (appointment && moment(appointment.date).add(1, 'days').format('YYYY-MM-DD') === moment(schedulingDate).format('YYYY-MM-DD')) {
      formRef?.current?.setFieldValue('hour', { label: appointment.hour, value: appointment.hour });
    }
  }, [availableHours]);

  const handleScheduleDate = useCallback(data => {
    formRef?.current?.setFieldValue('hour', undefined);
    setSchedulingDate(data);
  }, []);

  const handleTimeStart = useCallback(data => {
    if (data) {
      setTimeStart(data);
    }
  }, []);

  const handleSubmit = useCallback(
    async data => {
      setLoadingSubmit(true);

      data.date = !!data.date ? data.date : undefined;
      let formData: any;

      if (hasFileSelected) {
        data.files = selectedFiles;
      }

      if (data.files && data.files.length > 0) {
        formData = new FormData();

        data.files.map((attached: any, index: number) => {
          const filename = uuid();

          formData.append('file', attached.file, filename);
          data.files[index] = filename;
        });
      }

      try {
        const schema = Yup.object().shape({
          customer_name: Yup.string().required('Informe o nome'),
          customer_email: Yup.string().required('Informe o email'),
          responsible_email: Yup.string().required('Informe o email'),
          customer_phone: Yup.string().required('Informe o telefone'),
          files: Yup.string().nullable(),
          date: Yup.date()
            .required('A data de agendamento é obrigatória.')
            .nullable(),
          hour: Yup.string().required(
            'O horário de agendamento é obrigatório.',
          ),
          additional_informations: Yup.string().nullable(),
          confirmation_email_content: Yup.string().nullable(),
          user_id: Yup.string().nullable(),
        });

        await schema.validate(data, {
          abortEarly: false,
        });

        try {
          if (appointment_id && appointment_id === "novo") {
            await api.post(`schedules`, data);

            if (data.files && !!data.files) {
              await api.post(`schedules/file`, formData).catch((err) => console.log(err));
            }
          } else {
            await api.put(`schedules/${appointment_id}`, data);

            if (data.files && !!data.files) {
              await api.post(`schedules/file`, formData).catch((err) => console.log(err));
            }
          }

          addToast({
            type: 'success',
            title: 'Pronto!',
            description:
              `Agendamento ${appointment_id !== "novo" ? "atualizado" : "realizado"} com sucesso!`,
          });

          if (appointment_id && appointment_id === "novo") {
            history.push('/agenda');
          } else {
            setRefresh(!refresh);
          }
          setSelectedFiles([]);
          setLoadingSubmit(false);
          setEmailContentChange(false);
        } catch (err) {
          setLoadingSubmit(false);
          addToast({
            type: 'error',
            title: 'Ops!',
            description:
              err?.response ? err?.response?.data?.error?.error_message : 'Ocorreu um erro ao salvar, verifique os dados e tente novamente.',
          });
        }

      } catch (err) {
        setLoadingSubmit(false);
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);

          formRef.current?.setErrors(errors);
          addToast({
            type: 'error',
            title: 'Atenção!',
            description:
              'Preencha todos os campos obrigatórios.',
            timer: 6000
          });
          window.scrollTo({ top: 0, behavior: "smooth" });
          return;
        } else {
          addToast({
            type: 'error',
            title: 'Falha na requisição',
            description:
              err?.response ? err?.response?.data?.error?.error_message : 'Ocorreu um erro ao salvar, verifique os dados e tente novamente.',
          });
        }
      }

    },
    [timeStart, hasFileSelected, selectedFiles, refresh],
  );

  const handleFinish = useCallback(async () => {
    try {
      if (appointment) {
        confirmAlert({
          customUI: ({ onClose }) => {
            return (
              <div className="custom-ui">
                <h1>Confirmar ação?</h1>
                <p>Gostaria de concluir o atendimento?</p>
                <button onClick={onClose}>Não</button>
                <button
                  onClick={async () => {
                    const data = {
                      status: "Concluído"
                    };

                    await api.put(
                      `schedules/status/${appointment.id}`,
                      data,
                    );

                    addToast({
                      type: 'success',
                      title: 'Agendamento concluído com sucesso!',
                    });
                    history.push('/agenda');
                    onClose();
                  }}
                >
                  Sim, confirmar!
                </button>
              </div>
            );
          },
        });
      }
    } catch (err) {
      addToast({
        type: 'error',
        title: 'Falha na confirmação!',
        description: 'Confirme os dados e tente novamente',
      });
    }
  }, [appointment, history]);

  const handleConfirm = useCallback(async () => {
    try {
      if (appointment) {
        confirmAlert({
          customUI: ({ onClose }) => {
            return (
              <div className="custom-ui">
                <h1>Confirmar ação?</h1>
                <p>Gostaria de confirmar o atendimento?</p>
                <button onClick={onClose}>Não</button>
                <button
                  onClick={async () => {

                    const emailContent = formRef?.current?.getFieldValue('confirmation_email_content');
                    if (!emailContent || emailContent === "") {
                      addToast({
                        type: 'error',
                        title: 'Oops!',
                        description:
                          'Informe o conteúdo do e-mail de confirmação.',
                        timer: 6000
                      });
                      onClose();
                      return;
                    }

                    const data = {
                      status: "Confirmado"
                    };

                    await api.put(
                      `schedules/status/${appointment.id}`,
                      data,
                    );

                    addToast({
                      type: 'success',
                      title: 'Agendamento confirmado com sucesso!',
                    });
                    history.push('/agenda');
                    onClose();
                  }}
                >
                  Sim, confirmar!
                </button>
              </div>
            );
          },
        });
      }
    } catch (err) {
      addToast({
        type: 'error',
        title: 'Falha na confirmação!',
        description: 'Confirme os dados e tente novamente',
      });
    }
  }, [appointment, history]);

  const handleDeleteFile = useCallback(async (filename) => {
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <div className="custom-ui">
            <h1>Confirmar ação?</h1>
            <p>Gostaria de deletar este anexo?</p>
            <button onClick={onClose}>Não</button>
            <button
              onClick={async () => {
                await api.delete(`/schedules/${appointment?.id}/file/${filename}`)
                  .then((res) => {
                    addToast({
                      type: 'success',
                      title: 'Pronto!',
                      description:
                        'Deletado com sucesso.',
                    });
                    setRefresh(!refresh);
                  })
                  .catch((err) => {
                    addToast({
                      type: 'error',
                      title: 'Oops!',
                      description:
                        'Ocorreu um erro ao deletar, tente novamente.',
                    });
                  });
                onClose();
              }}
            >
              Sim, confirmar!
            </button>
          </div>
        );
      },
    });
  }, [appointment, refresh]);

  const handleCancel = useCallback(async () => {
    try {
      if (appointment) {
        confirmAlert({
          customUI: ({ onClose }) => {
            return (
              <div className="custom-ui">
                <h1>Confirmar ação?</h1>
                <p>Gostaria de cancelar o atendimento?</p>
                <button onClick={onClose}>Não</button>
                <button
                  onClick={async () => {
                    const data = {
                      status: "Cancelado"
                    };

                    await api.put(
                      `schedules/status/${appointment.id}`,
                      data,
                    );

                    addToast({
                      type: 'success',
                      title: 'Agendamento cancelado com sucesso!',
                    });

                    history.push('/agenda');
                    onClose();
                  }}
                >
                  Sim, cancelar!
                </button>
              </div>
            );
          },
        });
      }
    } catch (err) {
      addToast({
        type: 'error',
        title: 'Falha no cancelamento!',
        description: 'Confirme os dados e tente novamente',
      });
    }
  }, [appointment, history]);

  // called every time a file's `status` changes
  const handleChangeStatus = useCallback(({ meta, file }: any, status: any) => {
    if (status === "done") {
      selectedFiles.push({ meta, file });
      setSelectedFiles([...selectedFiles]);
    }

    if (status === "removed") {
      var files = selectedFiles.filter(fileAux => fileAux.meta.id !== meta.id);
      setSelectedFiles([...files]);
    }
  }, [selectedFiles]);

  useEffect(() => {
    if (selectedFiles.length > 0) {
      setHasFileSelected(true);
    } else {
      setHasFileSelected(false);
    }
  }, [selectedFiles]);

  return (
    <Main>
      <FormContainer>
        <div className="breadcrumb">
          <h2>Novo agendamento</h2>
        </div>
        <Form
          onSubmit={handleSubmit}
          ref={formRef}
        // initialData={appointment ? appointment : undefined}
        >
          <button type="button" onClick={() => history.go(-1)} className="close-btn">
            <FiXCircle size={30} />
          </button>

          <Row>
            <h2 className="subtitle">Informações do cliente</h2>
          </Row>
          <Row>
            <Input name="customer_name" label="Nome completo" />
            <div className="form-field">

              <InputMask
                label="Telefone"
                name="customer_phone"
                mask={phoneMask}
                formatChars={{ '9': '[0-9]', '?': '[0-9 ]' }}
                maskChar={null}
                onChange={event => {
                  if (event.target.value.length >= 15) {
                    setPhoneMask('(99) 99999-9999');
                  } else {
                    setPhoneMask('(99) 9999-9999?');
                  }
                }}
                fullWidth
              />
              {appointment && (
                <a
                  href={`https://api.whatsapp.com/send?phone=55${appointment?.customer_phone}`}
                  target="_blank"
                >
                  <FaWhatsapp
                    className={
                      appointment?.customer_phone && appointment?.customer_phone.length > 14
                        ? 'active whatsapp'
                        : 'inactive whatsapp'
                    }
                    color="#fff"
                  />
                </a>
              )}

            </div>

            <Input type="email" name="responsible_email" label="E-mail do responsável" />
            <Input type="email" name="customer_email" label="E-mail do candidato" />
          </Row>

          {/* <Row>
            <h2 className="subtitle">Anexo (opcional)</h2>
          </Row>
          <Row>
            <InputFile name="files" label="Anexo" onChange={() => setHasFileSelected(!hasFileSelected)} fullWidth />
            <div className="delete-file">
              {
                appointment && appointment?.files && !!appointment?.files && (
                  <>
                    <a href={`${appointment ? appointment.files : ""}`} target="_blank" className="view-file">
                      <p>
                        Visualizar
                        <AiFillEye size={22} />
                      </p>
                    </a>
                    <div onClick={handleDeleteFile}>
                      <p>Deletar arquivo</p>
                      <HiTrash size={22} />
                    </div>
                  </>
                )
              }

              {
                hasFileSelected && (
                  <div onClick={() => {
                    setHasFileSelected(!hasFileSelected);
                    formRef.current?.clearField(`files`);
                  }}>
                    <p>Remover selecionado</p>
                    <RiDeleteBack2Fill size={22} />
                  </div>
                )
              }
            </div>
          </Row> */}

          <br />

          <Row>
            <h2 className="subtitle">Detalhes sobre o agendamento</h2>
          </Row>
          <Row>
            <DatePicker
              name="date"
              label="Data do agendamento"
              placeholderText="Selecione a data do agendamento"
              dateFormat="dd/MM/yyyy"
              minDate={new Date()}
              filterDate={date => {
                return isAvailableDays(date);
              }}
              onSelect={handleScheduleDate}
              selected={schedulingDate ? schedulingDate : null}
              onMonthChange={handleMonthChange}
            />
            <Select
              name="hour"
              options={availableHours}
              label="Horário do agendamento"
              placeholder={
                schedulingDate
                  ? `Selecione o horário do atendimento`
                  : `Escolha uma data para liberar os horários disponíveis`
              }
              noOptionsMessage={() =>
                'Selecione uma data para carregar os horários'
              }
              isDisabled={schedulingDate === null}
              onChange={handleTimeStart}
            />

            <Select
              name="user_id"
              options={allCustomers}
              label="Vincular um cliente à este agendamento"
              placeholder={`Selecione o cliente`}
              noOptionsMessage={() =>
                'Sem resultados...'
              }
            />
          </Row>

          <Row>
            <h2 className="subtitle">Informações adicionais</h2>
          </Row>
          <Row>
            <Textarea name="additional_informations" label="Faça uma breve observação" fullWidth />
            <Textarea name="confirmation_email_content" label="Descreva o texto do e-mail de confirmação" fullWidth
              onInput={(e) => {
                setEmailContentChange(true);
              }}
            />
          </Row>
          <Row>
            <h2 className="subtitle">Anexo(s) (opcional)</h2>
          </Row>

          {
            appointment && (
              appointment.files.length > 0 && (
                <Row>
                  <h3>Confira os anexos deste agendamento</h3>

                  <div className="all-files">
                    {appointment.files.map((file: any) => (
                      <Row className="actions-of-files">
                        <p>
                          Nome gerado automaticamente {file.name}
                        </p>
                        <p>Data de upload: {moment(file.created_at).format('DD/MM/YYYY')}</p>

                        <div className="actions">
                          <a href={`${file.signed_url}`} target="_blank" className="view-file">
                            <p>
                              Visualizar
                              <AiFillEye size={22} />
                            </p>
                          </a>
                          <div onClick={() => handleDeleteFile(file.name)} className="delete-file">
                            <p>Deletar arquivo</p>
                            <HiTrash size={22} />
                          </div>
                        </div>
                      </Row>
                    ))}
                  </div>
                </Row>
              )
            )
          }

          <Row>
            <Dropzone
              multiple
              onChangeStatus={handleChangeStatus}
              accept="image/*,application/*,text/*"
              inputContent="Arraste para anexar arquivo(s) ou clique para selecionar"
              inputWithFilesContent="Anexar mais arquivo(s)"
            />
          </Row>

          <Row>
            {appointment && (
              <div>
                <Button type="button" className="finish" onClick={handleFinish} disabled={appointment.status === 'Concluído'}>
                  {appointment.status !== 'Concluído' ? "Finalizar" : "Concluído"}
                </Button>
                {appointment.status !== 'Confirmado' && appointment.status !== 'Concluído' && (
                  <Button type="button" className="confirm" onClick={handleConfirm} disabled={emailContentChange}>
                    {emailContentChange ? "Salve as alterações" : "Confirmar"}
                  </Button>
                )}
                {appointment.status !== 'Cancelado' && appointment.status !== 'Concluído' && (
                  <Button type="button" className="cancel" onClick={handleCancel}>
                    Cancelar
                  </Button>
                )}
              </div>
            )}
            <div className={appointment ? "to-right" : "buttons"}>
              <Button type="submit" disabled={loadingSubmit}>
                {appointment
                  ? loadingSubmit
                    ? `Atualizando...`
                    : `Atualizar`
                  : loadingSubmit
                    ? `Salvando...`
                    : `Salvar`}
              </Button>
            </div>
          </Row>

        </Form>
      </FormContainer>
    </Main>
  );
};

export default FormC;
