import styled, { keyframes } from 'styled-components';
import { shade } from 'polished';

const appearFromTop = keyframes`
  from {
    opacity: 0;
    transform: translateY(-50px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
`;

export const Container = styled.div`
  padding: 0 50px;
  place-items: center;
  flex-direction: column;

  @media (max-width: 769px) {
    padding: 0 15px;
  }

  tr {
    &.Pendente {
      background-color: #f9ffb8 !important;

      td {
        color: #000 !important;
      }
    }

    &.Confirmado {
      background-color: #c4f38e !important;

      td {
        color: #000 !important;
      }
    }

    &.Cancelado {
      background-color: #ff8787 !important;

      td {
        color: #000 !important;
      }
    }

    &.Concluído {
      background-color: #ddd !important;

      td {
        color: #000 !important;
      }
    }
  }
`;

export const FormContainer = styled.div`
  padding: 0 50px;
  place-items: center;
  flex-direction: column;

  @media (max-width: 768px) {
    padding: 0 15px;
  }

  @media (max-width: 769px) {
    padding: 0 15px;
  }

  form {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    position: relative;
    background: #fff;
    padding: 30px;
    border-radius: 5px;
    margin-bottom: 30px;

    .close-btn {
      position: absolute;
      right: 0;
      top: -50px;
      padding: 0;
      line-height: 20px;
      background: transparent;
      border: 0;
      color: #181818;
    }

    button[type='submit'] {
      border-radius: 5px;
    }

    button {
      border-radius: 5px;
      /* max-width: 48%; */

      &.confirm {
        background-color: #40c77c;
        border-color: #40c77c;

        &:hover {
          color: #fff;
          background: ${shade(0.2, '#40c77c')};
        }
      }

      &.cancel {
        background-color: #c53030;
        border-color: #c53030;

        &:hover {
          color: #fff;
          background: ${shade(0.2, '#c53030')};
        }
      }
    }

    .to-right {
      @media (max-width: 769px) {
        /* max-width: 48%; */

        button {
          max-width: 100%;
          width: 237px;
        }
      }
    }

    button + button {
      margin-right: 15px;
    }

    .attendance-days {
      width: 100%;
      margin-bottom: 30px;
      padding-bottom: 30px;
      border-bottom: 1px solid #cfcfcf;

      label {
        font-weight: 500;
        margin-top: 10px;
        margin-bottom: 10px;
        display: block;
        color: #505050;
        font-size: 18px;
      }

      .new-day {
        display: flex;
        width: 100%;
        justify-content: space-between;
        align-items: flex-end;
        flex-wrap: wrap;
        /* border: 2px dashed #f1f1f1; */
        background: #f7f7f7;
        border-radius: 5px;
        padding: 25px;
        margin: 25px 0;

        @media (max-width: 769px) {
          flex-wrap: wrap;
          padding-top: 20px;

          & + .new-day {
            border-top: 1px solid #ddd;
          }
        }

        /* > div:nth-child(1),
        > div:nth-child(2) {
          max-width: calc(50% / 2 - 15px);
          width: 100%;

          @media (max-width: 769px) {
            max-width: 100%;
          }
        }

        &.vaccine {
          > div:nth-child(1),
          > div:nth-child(2) {
            max-width: calc(100% / 2 - 45px);
            width: 100%;

            @media (max-width: 769px) {
              max-width: 100%;
            }
          }
        } */

        /* .label {
          max-width: calc((50% - 15px) / 2 - 10px - 25px);

          @media (max-width: 769px) {
            max-width: calc(100% / 2 - 10px - 20px);
          }
        } */
      }

      button.delete {
        height: 40px;
        width: 40px;
        border-radius: 5px;
        border: 0;
        background: #ff1329;
        color: #fff;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-bottom: 20px;
      }

      button.add-day {
        display: flex;
        float: right;
        width: 50px;
        height: 50px;
        border-radius: 10px;
        border: 1px solid #ddd;
        background: #181818;
        font-size: 35px;
        align-items: center;
        justify-content: center;
        line-height: 0;
        padding-bottom: 7px;
        border-radius: 50%;
        color: #fff;

        @media (max-width: 769px) {
          float: none;
          margin: 20px auto 0;
        }
      }
    }
  }

  .alert-box {
    background: #ddd;
    padding: 20px;
    border-radius: 5px;
    font-size: 16px;
    font-weight: 500;
    display: flex;
    justify-content: center;
    margin-bottom: 10px;
  }

  .no-results {
    padding: 30px;
    background: #f1f1f1;
    border-radius: 5px;
    margin-bottom: 10px;
    width: 100%;
    margin-top: 5px;

    p {
      font-size: 14px;
      font-weight: 500;
      color: #6a6a6a;
    }
  }

  .scope {
    padding: 15px;
    background: #F5F5F5;
    border-radius: 5px;
    margin-bottom: 10px;
    position: relative;
    width: 100%;
    margin-top: 5px;

    .rm {
      position: absolute;
      right: 10px;
      top: 0px;
      width: 30px;
      height: 30px;
      border-radius: 0 0 5px 5px;
      border: 0;
      background: #c53030;
      display: flex;
      justify-content: center;
      align-items: center;
      color: #fff;
      font-size: 22px;
      cursor: pointer;

      &:hover {
        background-color: #8f2222;
      }
    }

    .box-file {
      padding: 8px;
      height: 38px;
      background: #ffffff;
      margin-top: 20px;
      border-radius: 5px;
      cursor: pointer;
      position: relative;
      width: 32%;

      label {
        cursor: pointer;
        width: 100%
      }

      .input {
        background-color: transparent;
        position: absolute;
        right: 0;
        top: 0;
        pointer-events: none;

        > div {
          pointer-events: all;
        }

        input {
          visibility: hidden;
        }
      }

      p {
        svg {
          color: #181818;
        }
      }

      svg {
        float: left;
        margin-right: 7px;
        color: #181818;
      }

      div {
        > div {
          /* display: none; */
        }
      }

    }
  }


    button.new-btn {
      display: inline-flex;
      margin-left: auto;
      margin-bottom: 20px;
      background: transparent;
      border: 0;
      color: #181818;
      cursor: pointer;
      align-items: center;

      svg {
        background-color: #181818;
        color: #fff;
        font-size: 30px;
        padding: 5px;
        margin-left: 10px;
        border-radius: 3px;
        font-weight: 600;
      }
    }
`;

export const Row = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;

  &.row-buttons {
    justify-content: space-between;
    border-top: 1px solid #707070;
    margin-top: 20px;
    padding-top: 30px;

    > div {
      display: flex;

      @media(max-width: 769px) {
        width: 100%;
      }
    }

    button {
      width: 250px;

      @media(max-width: 769px) {
        width: 100%;
        margin: 0 !important;
      }

      &.next {
        background: #fff;
        border: 2px solid #181818;
        color: #181818;

        @media(max-width: 769px) {
          display: none;
        }
      }

      &.prev {
        background: #fff;
        border: 2px solid #a5a5a5;
        color: #a5a5a5;

        @media(max-width: 769px) {
          display: none;
        }
      }

      & + button {
        margin-left: 20px;
      }
    }
  }
`;

export const Col = styled.div`
  width: 32%;

  @media (max-width: 769px) {
    width: 100%;
  }

  > div {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    justify-content: space-between;
  }
`;
